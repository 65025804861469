.similar-article{
  background-color: $grey-100;
}
.similar-articles__items{
  padding: 33px 0px 50px;

  @media(min-width: $xs) {
    padding: 67px 42px 100px;
  }
  
  &__title{
    @include heading-2($grey-900);
    margin-bottom: 40px;
  }
  &__content{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(Min(300px,100%), 1fr));
    grid-gap: 18px;

  }
}
