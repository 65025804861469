.payment-summary {
  .basic-modal {
    &__main {
      @media(min-width: $md) {
        max-width: 640px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: rgba($grey-500, .2);
      border-radius: 4px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &__header {
    min-height: 132px;
    position: relative;
    background-image: url("/img/modal-header-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    padding: 40px 0;
  }

  &__reward {
    padding: 0 24px;
    margin-bottom: 36px;

    @media(min-width: $md) {
      padding: 0 70px;
    }

    &__title {
      margin-bottom: 8px;
      @include heading-3();
    }

    &__card {
      border: solid 1px $grey-100;
      border-radius: 2px;
    }
  }

  &__platform-support {
    padding: 0 24px;
    margin-bottom: 36px;

    @media(min-width: $md) {
      padding: 0 70px;
    }

    &__title {
      margin-bottom: 8px;
      @include heading-3();
    }

    &__desc {
      margin-bottom: 16px;
      @include text(14px);
    }

    &__buttons {
    }
  }

  &__support-amount {
    padding: 32px 24px;
    margin-bottom: 40px;
    background-color: $primary;

    @media(min-width: $md) {
      padding: 32px 70px;
    }

    &__title {
      margin-bottom: 8px;
      @include heading-3($white);
    }

    &__dropdown {
      .dropdown {
        &__placeholder {
          display: none;
        }

        &__arrow {
          top: 30px;
        }
      }

      .choices {
        &__list {
          &--dropdown {
            top: calc(100% - 4px);
          }
        }

        &__item {
          @include text(16px, $grey-900, bold);
        }
      }

      .dropdown {
        .choices__inner {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    &__custom {
      margin-top: 12px;
      border-radius: 8px;
      overflow: hidden;

      .text-input__native:not(:placeholder-shown) ~ .text-input__placeholder,
      .text-input__native:focus ~ .text-input__placeholder {
        display: none;
      }
    }
  }

  &__costs {
    padding: 12px 16px;
    margin: 0 24px 22px;
    background-color: $grey-50;
    border: solid 1px $grey-100;
    border-radius: 4px;

    @media(min-width: $md) {
      margin: 0 70px 22px;
    }

    &__line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 6px 0;

      &:not(:last-child) {
        border-bottom: solid 1px $grey-100;
      }

      &__name {
        margin-right: 24px;
        @include text(16px);
      }

      &__value {
        white-space: nowrap;
        @include text(16px, $grey-900, bold);

        &--danger {
          color: $red-500;
        }
      }
    }

    &__total {
      margin-top: 12px;
      text-align: right;
      @include heading-3($primary);
    }
  }

  &__agreements {
    display: grid;
    grid-gap: 12px;
    max-width: 460px;
    padding: 0 24px;
    margin: 0 auto 30px;
  }

  &__submit {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    padding: 0 24px;
    margin: 0 auto;

    &__desc {
      margin-top: 8px;
      text-align: center;
      @include text(12px);
    }
  }

}
