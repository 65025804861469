.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $navbar-height * 2 0 $navbar-height;

  @media(min-width: $md) {
    padding: $navbar-height * 4 0 $navbar-height * 3
  }

  &__code {
    @include heading-1plus($red-500);
  }

  &__desc {
    margin-bottom: 32px;
    @include text(16px);

    @media(min-width: $sm) {
      @include text(18px);
    }
  }
}
