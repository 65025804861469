.campaigns-filters {
  padding: 8px 12px;
  display: block;
  flex-wrap: wrap;
  overflow: hidden;

  @media(min-width: $md) {
    display: flex;
  }

  @media(min-width: $lg) {
    display: block;
  }

  &__group {
    padding: 16px 0;

    &--full {
      width: 100%;
    }

    &--col {
      flex: 1 1 0;
    }

    &:not(:last-child) {
      border-bottom: solid 1px $grey-100;

      @media(min-width: $md) {
        border-bottom: none;
      }

      @media(min-width: $lg) {
        border-bottom: solid 1px $grey-100;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: margin-bottom 350ms;

      &.active {
        margin-bottom: 12px;

        .campaigns-filters__group__header__arrow {
          transform: rotate(180deg);
        }
      }

      &__icon {
        margin-right: 12px;
      }

      &__title {
        margin-right: 12px;
        @include text(14px, $primary, bold);
        text-transform: uppercase;
      }

      &__arrow {
        margin-left: auto;
        transition: transform 350ms;

        @media(min-width: $md) {
          margin-right: 16px;
        }

        @media(min-width: $lg) {
          margin-right: 0;
        }
      }
    }

    &__search {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;

      @media(min-width: $md) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 26px;
      }

      @media(min-width: $lg) {
        grid-template-columns: 1fr;
        grid-gap: 16px;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;

      .checkbox {
        overflow: hidden;

        &__label {
          @include crop-text;
        }
      }
    }
  }

  &__topbar {
    display: flex;
    justify-content: space-between;

    &__title {
      @include heading-3();
    }

    &__clear {
      @include flex-center;
      @include label($grey-900, true);
      padding: 8px 16px;
      border-radius: 60px;
      background-color: $grey-100;
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        text-decoration: none;
      }

      svg {
        margin-left: 4px;
      }
    }
  }
}
