.categories-masonry {
  &__title {
    @include heading-1plus($primary);
  }

  &__desc {
    margin-top: 24px;
    max-width: 760px;
    @include text(20px, $grey-500, bold);
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  &__tile {
    flex: 1 1 380px;
    display: block;
    height: 260px;
    padding: 30px 26px 30px 30px;
    position: relative;
    z-index: 0;

    &:hover {
      text-decoration: none;

      .categories-masonry__tile {
        &__overlay {
          background-color: $primary-darker;
        }

        &__name {
          span {
            top: 100%;
            transform: translateY(-100%);
          }
        }

        &__count {
          opacity: 1;
          transform: translateY(0);
        }

        &__button {
          circle {
            fill: rgba($white, 0.3);
            stroke: $transparent;
          }
        }
      }
    }

    &__bg,
    &__overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__bg {
      object-fit: cover;
      z-index: -2;
    }

    &__overlay {
      background-color: rgba($black, 0.15);
      z-index: -1;
      transition: background-color 350ms;
    }

    &__content {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
    }

    &__name {
      flex-grow: 1;
      max-width: calc(100% - 64px);
      margin-bottom: 8px;
      position: relative;
      @include heading-3($white);
      @include crop-text;

      span {
        position: absolute;
        left: 0;
        top: 0;
        transition: top 350ms, transform 350ms;
      }
    }

    &__count {
      @include label($grey-100, true);
      opacity: 0;
      transition: opacity 350ms, transform 350ms;
      transform: translateY(75%);
    }

    &__button {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;

      circle {
        stroke: $white;
        fill: $transparent;
        transition: fill 350ms, stroke 350ms;
      }
    }
  }
}
