.faq-page {
  &__content {
    display: flex;
    padding: 64px 24px;
    background-color: $white;
    flex-direction: column-reverse;
    
    @media(min-width: $md) {
      flex-direction: row;
    }

    @media(min-width: $lg) {
      padding: 90px 74px;
      margin-top: 64px;
    }
  }

  &__main {
    flex: 1 1 0;
    max-width: 846px;
    margin-right: 36px;

    @media(min-width: $lg) {
      margin-right: 0;
      padding: 0 36px;
    }
  }

  &__aside {
    margin-bottom: 64px;

    @media(min-width: $lg) {
      flex: 0 1 382px;
      padding: 0 36px;
    }

    &__menu {
      @media(min-width: $md) {
        position: sticky;
        top: $navbar-height * 2;
      }
    }
  }

  &__search {
    margin-bottom: 30px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;

    &__group {
      &__title {
        margin-bottom: 12px;
        @include heading-3($primary);
      }

      &__items {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 12px;
      }
    }

    &__empty-info {
      @include text(16px);
    }
  }
}
