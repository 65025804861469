b {
  font-weight: bold;
}

i {
  font-style: italic;
}

.wrapper {
  padding: 0 24px;
  display: flex;
  justify-content: center;
}
@media (min-width: 1200px) {
  .wrapper {
    padding: 0 96px;
  }
}
.wrapper--flat {
  padding: 0;
}
.wrapper--content-right {
  justify-content: flex-end;
}
.wrapper--content-left {
  justify-content: flex-start;
}

.container {
  width: 100%;
  max-width: 1518px;
}
.container--small {
  max-width: 1322px;
}
.container--large {
  max-width: 1710px;
}
.container--fluid {
  max-width: 100%;
}

.scroll-header--active .scroll-header__header {
  padding-top: 0;
  padding-bottom: 0;
  background: #00A767;
}
.scroll-header--active .scroll-header__content {
  padding-top: 460px;
}
.header {
  padding-top: 190px;
  padding-bottom: 136px;
  background-color: #00A767;
  transition-property: padding;
  transition-duration: 350ms;
}
@media (min-width: 768px) {
  .header {
    background-image: url("/img/header-pattern-bg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto calc(100% - 32px - 80px);
  }
}
.header--scroll {
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 10;
  top: 80px;
  left: 0;
  width: 100%;
  height: 120px;
  background: #00A767;
}
.header--scroll .header__title {
  color: #FFF;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .header--scroll .header__title {
    font-size: 38px;
    line-height: 52px;
  }
}
.header--scroll .header__desc {
  margin-top: 0;
  font-size: 0;
  line-height: 0;
}
.header--has-tabs {
  padding-bottom: 0;
}
.header__title {
  color: #FFF;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
  transition: font-size 350ms;
}
@media (min-width: 576px) {
  .header__title {
    font-size: 64px;
    line-height: 72px;
  }
}
.header__desc {
  color: #FFF;
  font-size: 22px;
  line-height: 30px;
  font-weight: normal;
  margin-top: 18px;
  transition: font-size 350ms, margin-top 350ms;
}
@media (min-width: 576px) {
  .header__desc {
    font-size: 32px;
    line-height: 40px;
  }
}
.header__tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
}
@media (min-width: 576px) {
  .header__tabs {
    flex-direction: row;
  }
}
.header__tabs__item {
  position: relative;
  padding: 24px 0;
  transition: color 350ms;
  color: #CFEEE1;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .header__tabs__item {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .header__tabs__item {
    color: #CFEEE1;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .header__tabs__item {
    font-size: 24px;
    line-height: 32px;
  }
}
.header__tabs__item:hover {
  text-decoration: none;
  color: #FFF;
}
.header__tabs__item--active {
  color: #FFF;
}
.header__tabs__item--active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (min-width: 992px) {
  .header__tabs__item--active::after {
    width: calc(100% + 32px);
    height: 4px;
  }
}
.header__tabs__item:not(:last-child) {
  margin-right: 52px;
}
@media (min-width: 992px) {
  .header__tabs__item:not(:last-child) {
    margin-right: 100px;
  }
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .section--small {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .section:not(.section--small) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section:not(.section--small):first-child {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .section:not(.section--small):first-child {
    padding-top: 120px;
  }
}
.section:not(.section--small):last-child {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .section:not(.section--small):last-child {
    padding-bottom: 120px;
  }
}

.d-none {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.wrap-text {
  word-break: break-word;
}

.text-nowrap {
  white-space: nowrap;
}

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-success {
  color: #00A767;
}

.text-danger {
  color: #FF5A55;
}

.text-warning {
  color: #FBC121;
}

.text-info {
  color: #3FA2F7;
}

.homepage__section-claim {
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  max-width: 1080px;
}
@media (min-width: 576px) {
  .homepage__section-claim {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .homepage__section-claim {
    color: #2E2F38;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .homepage__section-claim {
    font-size: 38px;
    line-height: 52px;
  }
}
@media (max-width: 576px) {
  .homepage__about-section {
    padding-bottom: 0 !important;
  }
}

.contact-page__header {
  background-position: right 0 bottom 80px;
  background-size: auto calc(100% - 32px - 168px);
}
.contact-page__content {
  background-color: #FFF;
}
.contact-page__content__main {
  display: flex;
  flex-direction: column-reverse;
  padding: 64px 32px;
  margin-top: -84px;
}
@media (min-width: 992px) {
  .contact-page__content__main {
    padding: 90px 32px;
  }
}
@media (min-width: 1200px) {
  .contact-page__content__main {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 90px 32px 152px;
  }
}
.contact-page__form, .contact-page__details {
  flex: 1 0 0;
}
@media (min-width: 992px) {
  .contact-page__form, .contact-page__details {
    padding: 0 55px;
  }
}
.contact-page__details {
  margin-bottom: 42px;
}
@media (min-width: 1200px) {
  .contact-page__details {
    margin-bottom: 0;
  }
}
.contact-page__details__inner {
  max-width: 420px;
}
.contact-page__map iframe {
  display: block;
  width: 100%;
  height: 512px;
}

.faq-page__content {
  display: flex;
  padding: 64px 24px;
  background-color: #FFF;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .faq-page__content {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .faq-page__content {
    padding: 90px 74px;
    margin-top: 64px;
  }
}
.faq-page__main {
  flex: 1 1 0;
  max-width: 846px;
  margin-right: 36px;
}
@media (min-width: 992px) {
  .faq-page__main {
    margin-right: 0;
    padding: 0 36px;
  }
}
.faq-page__aside {
  margin-bottom: 64px;
}
@media (min-width: 992px) {
  .faq-page__aside {
    flex: 0 1 382px;
    padding: 0 36px;
  }
}
@media (min-width: 768px) {
  .faq-page__aside__menu {
    position: sticky;
    top: 160px;
  }
}
.faq-page__search {
  margin-bottom: 30px;
}
.faq-page__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
}
.faq-page__list__group__title {
  margin-bottom: 12px;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .faq-page__list__group__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.faq-page__list__group__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
}
.faq-page__list__empty-info {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}

.how-it-works-page__content {
  margin-top: 64px;
}
.how-it-works-page__content__leading__bar {
  height: 68px;
  background-color: #FFF;
  margin-left: 120px;
  display: none;
}
@media (min-width: 1200px) {
  .how-it-works-page__content__leading__bar {
    display: block;
  }
}
.how-it-works-page__content__main {
  background-color: #FFF;
  padding: 100px 0 90px;
}
@media (min-width: 1200px) {
  .how-it-works-page__content__main {
    margin-left: 120px;
  }
}
.how-it-works-page__lead {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .how-it-works-page__lead {
    flex-direction: row;
  }
}
.how-it-works-page__lead__image {
  background-color: #FFF;
  margin-top: 64px;
  padding: 0 32px 64px;
}
@media (min-width: 1200px) {
  .how-it-works-page__lead__image {
    margin: 0;
    padding: 0 32px 0 0;
    width: 50%;
  }
}
.how-it-works-page__lead__image img {
  display: block;
  max-width: 100%;
  margin-top: -64px;
}
@media (min-width: 1200px) {
  .how-it-works-page__lead__image img {
    margin-top: 0;
  }
}
.how-it-works-page__lead__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 36px;
  background-color: #FFF;
}
@media (min-width: 1200px) {
  .how-it-works-page__lead__content {
    padding: 0 72px;
    width: 50%;
  }
}
.how-it-works-page__lead__content__title, .how-it-works-page__lead__content__desc, .how-it-works-page__lead__content__text {
  max-width: 540px;
}
.how-it-works-page__lead__content__title {
  margin-bottom: 50px;
  color: #00A767;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .how-it-works-page__lead__content__title {
    font-size: 38px;
    line-height: 52px;
  }
}
.how-it-works-page__lead__content__desc {
  margin-bottom: 28px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .how-it-works-page__lead__content__desc {
    font-size: 24px;
    line-height: 32px;
  }
}
.how-it-works-page__lead__content__text {
  font-size: 18px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.how-it-works-page__step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  .how-it-works-page__step {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .how-it-works-page__step {
    padding-right: 5vw;
  }
}
.how-it-works-page__step:not(:last-child) {
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .how-it-works-page__step:nth-child(even) {
    flex-direction: row-reverse;
  }
}
.how-it-works-page__step:nth-child(odd) .how-it-works-page__step__image {
  text-align: right;
}
.how-it-works-page__step__article {
  flex: 1 1 0;
  padding: 0 32px;
  margin-bottom: 42px;
  position: relative;
  z-index: 0;
}
@media (min-width: 992px) {
  .how-it-works-page__step__article {
    padding: 0 72px;
    margin-bottom: 0;
  }
}
.how-it-works-page__step__article__title {
  margin-bottom: 8px;
  max-width: 576px;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .how-it-works-page__step__article__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.how-it-works-page__step__article__text {
  max-width: 576px;
  font-size: 18px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.how-it-works-page__step__article__number {
  position: absolute;
  font-weight: bold;
  font-size: 144px;
  line-height: 112px;
  color: #E5F5EF;
  font-family: "proxima-nova", sans-serif;
  top: -32px;
  left: 24px;
  z-index: -1;
  pointer-events: none;
}
.how-it-works-page__step__image {
  flex: 1 1 0;
  padding: 0 32px;
}
@media (min-width: 992px) {
  .how-it-works-page__step__image {
    padding: 0 72px;
  }
}
.how-it-works-page__step__image img {
  max-width: 100%;
}
.how-it-works-page__read-more {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.how-it-works-page__read-more__inner {
  display: flex;
}
.how-it-works-page__read-more__text {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #000;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .how-it-works-page__read-more__text {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .how-it-works-page__read-more__text {
    flex-direction: row;
  }
}
.how-it-works-page__read-more__text span {
  color: #00A767;
}
@media (min-width: 768px) {
  .how-it-works-page__read-more__text span {
    margin-left: 16px;
  }
}

.about-page__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #00A767;
  padding: 158px 32px 0;
}
.about-page__hero__claimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 848px;
  margin-bottom: 48px;
}
@media (min-width: 576px) {
  .about-page__hero__claimer {
    margin-bottom: 82px;
  }
}
@media (min-width: 1200px) {
  .about-page__hero__claimer {
    margin-bottom: 18px;
  }
}
.about-page__hero__claimer__brand {
  width: 100%;
  max-width: 414px;
  margin-bottom: 36px;
}
.about-page__hero__claimer__title {
  color: #FFF;
  font-size: 22px;
  line-height: 30px;
  font-weight: normal;
  text-align: center;
}
@media (min-width: 576px) {
  .about-page__hero__claimer__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.about-page__hero__main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .about-page__hero__main {
    flex-wrap: nowrap;
  }
}
.about-page__hero__main__tree {
  margin: auto 0 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
  order: 1;
}
@media (min-width: 576px) {
  .about-page__hero__main__tree {
    margin: auto 82px 0;
  }
}
@media (min-width: 1200px) {
  .about-page__hero__main__tree {
    order: 0;
    margin: auto -72px 0;
  }
}
.about-page__hero__main__tree::after {
  content: "";
  display: block;
  width: 95%;
  padding-top: 95%;
  position: absolute;
  left: 50%;
  top: 22px;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: #00975D;
  z-index: -1;
}
.about-page__hero__main__tree img {
  display: block;
  max-width: 100%;
}
.about-page__hero__main__brand {
  margin: 32px 42px;
  max-width: 100%;
}
@media (min-width: 576px) {
  .about-page__hero__main__brand {
    margin: 0 42px 82px;
  }
}
@media (min-width: 1200px) {
  .about-page__hero__main__brand {
    margin: 0 0 14vw;
  }
}
@media (min-width: 1200px) {
  .about-page__jumbotron {
    margin-right: 84px;
  }
}
.about-page__support {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0 40px;
  padding-bottom: 42px;
  background-color: #FFF;
}
@media (min-width: 1200px) {
  .about-page__support {
    margin: 240px 0 184px;
  }
}
@media (min-width: 1200px) {
  .about-page__support {
    margin-right: 84px;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
  }
}
.about-page__support__payment {
  align-self: center;
  max-width: 660px;
}
@media (min-width: 1200px) {
  .about-page__support__payment {
    margin-right: 32px;
  }
}
.about-page__support__payment__title, .about-page__support__payment__desc {
  padding: 0 24px;
}
@media (min-width: 768px) {
  .about-page__support__payment__title, .about-page__support__payment__desc {
    padding: 0 60px;
  }
}
@media (min-width: 1200px) {
  .about-page__support__payment__title, .about-page__support__payment__desc {
    margin-left: 60px;
    padding: 0;
  }
}
.about-page__support__payment__title {
  margin-top: 92px;
  margin-bottom: 8px;
  padding: 0 24px;
  color: #2E2F38;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .about-page__support__payment__title {
    font-size: 38px;
    line-height: 52px;
  }
}
@media (min-width: 768px) {
  .about-page__support__payment__title {
    padding: 0 60px;
  }
}
@media (min-width: 1200px) {
  .about-page__support__payment__title {
    padding: 0;
  }
}
.about-page__support__payment__title span {
  color: #00A767;
}
.about-page__support__payment__subtitle {
  margin-top: 24px;
  margin-bottom: 12px;
  padding: 0 24px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .about-page__support__payment__subtitle {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 768px) {
  .about-page__support__payment__subtitle {
    padding: 0 60px;
  }
}
@media (min-width: 1200px) {
  .about-page__support__payment__subtitle {
    margin-left: 60px;
    padding: 0;
  }
}
.about-page__support__payment__desc {
  font-size: 18px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.about-page__support__payment__card {
  margin-top: 32px;
}
.about-page__support__image {
  display: flex;
  align-items: flex-end;
}
@media (min-width: 1200px) {
  .about-page__support__image {
    margin-top: -64px;
    margin-right: 84px;
    margin-bottom: -64px;
  }
}
.about-page__support__image img {
  max-width: 100%;
}
.about-page__section-title-separator {
  max-width: 1240px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .about-page__section-title-separator {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .about-page__section-title-separator {
    padding: 42px 0;
  }
}
.about-page__section-title-separator--small {
  max-width: 1080px;
}
@media (min-width: 768px) {
  .about-page__section-title-separator {
    color: #2E2F38;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .about-page__section-title-separator {
    font-size: 38px;
    line-height: 52px;
  }
}
.about-page__section-title-separator span {
  color: #00A767;
}

.campaigns-page__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .campaigns-page__content {
    align-items: flex-start;
    flex-direction: row;
  }
}
.campaigns-page__filters {
  margin-bottom: 36px;
  background-color: #FFF;
  border-radius: 4px;
  overflow: auto;
  max-height: calc(96vh - 120px);
}
@media (min-width: 992px) {
  .campaigns-page__filters {
    flex: 1 0 310px;
    position: sticky;
    top: 120px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.campaigns-page__campaigns {
  width: 100%;
}
.campaigns-page__campaigns__loader {
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaigns-page__campaigns__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 16px;
}
.campaigns-page__campaigns__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.campaigns-page__campaigns__empty__image {
  margin-bottom: 48px;
}
.campaigns-page__campaigns__empty__text, .campaigns-page__campaigns__empty__advice {
  color: #828288;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaigns-page__campaigns__empty__text, .campaigns-page__campaigns__empty__advice {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaigns-page__campaigns__empty__advice {
  color: #00A767;
}
.campaigns-page__campaigns__load-btn {
  max-width: 310px;
  width: 100%;
  margin: 64px auto 0;
}

.campaign-preview {
  padding-top: 120px;
  padding-bottom: 110px;
}
.campaign-preview__alerts {
  display: grid;
  grid-gap: 8px;
  margin-bottom: 16px;
}
.campaign-preview__alerts__alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 58px;
  padding: 8px 16px;
}
@media (min-width: 576px) {
  .campaign-preview__alerts__alert {
    flex-direction: row;
  }
}
.campaign-preview__alerts__alert__icon {
  flex-shrink: 0;
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .campaign-preview__alerts__alert__icon {
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.campaign-preview__alerts__alert__text {
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .campaign-preview__alerts__alert__text {
    margin-right: 24px;
    margin-bottom: 0;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .campaign-preview__alerts__alert__text {
    font-size: 16px;
    color: #2E2F38;
    font-weight: bold;
    line-height: 1.75em;
  }
}
.campaign-preview__alerts__alert__actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  flex-shrink: 0;
}
@media (min-width: 576px) {
  .campaign-preview__alerts__alert__actions {
    margin-left: auto;
  }
}
.campaign-preview__alerts__alert--info {
  background-color: #EDF7FF;
  border: solid 1px #3FA2F7;
}
.campaign-preview__alerts__alert--info .campaign-preview__alerts__alert__icon path {
  fill: #3FA2F7;
}
.campaign-preview__alerts__alert--warning {
  background-color: #FFFBF1;
  border: solid 1px #FBC121;
}
.campaign-preview__alerts__alert--warning .campaign-preview__alerts__alert__icon path {
  fill: #FBC121;
}
.campaign-preview__alerts__alert--danger {
  background-color: #FFE0DF;
  border: solid 1px #FF5A55;
}
.campaign-preview__alerts__alert--danger .campaign-preview__alerts__alert__icon path {
  fill: #FF5A55;
}
.campaign-preview__content {
  display: flex;
  flex-direction: column-reverse;
}
.campaign-preview__content--reverse {
  flex-direction: column;
}
@media (min-width: 992px) {
  .campaign-preview__content {
    flex-direction: row;
  }
}
.campaign-preview__main {
  width: 100%;
  margin-right: 18px;
}
.campaign-preview__aside {
  width: 100%;
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .campaign-preview__aside {
    max-width: 400px;
    margin-bottom: 0;
  }
}

.custom-page {
  padding: 160px 0 80px;
}
.custom-page__content {
  max-width: 800px;
  margin: 0 auto;
}
.custom-page__content h3 {
  margin-bottom: 16px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .custom-page__content h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.custom-page__content p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.custom-page__content strong {
  font-weight: bold;
}
.custom-page__content ul,
.custom-page__content ol {
  margin-bottom: 16px;
  padding-left: 18px;
}
.custom-page__content ul li,
.custom-page__content ol li {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.custom-page__content ul li:not(:last-child),
.custom-page__content ol li:not(:last-child) {
  margin-bottom: 16px;
}
.custom-page__content ol {
  list-style-type: decimal;
}
.custom-page__content ul {
  list-style-type: disc;
}
.custom-page__content figure {
  margin-bottom: 16px;
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 0 80px;
}
@media (min-width: 768px) {
  .error-page {
    padding: 320px 0 240px;
  }
}
.error-page__code {
  color: #FF5A55;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .error-page__code {
    font-size: 64px;
    line-height: 72px;
  }
}
.error-page__desc {
  margin-bottom: 32px;
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .error-page__desc {
    font-size: 18px;
    color: #2E2F38;
    font-weight: normal;
    line-height: 1.75em;
  }
}

.support-page .about-page__support {
  margin: 0;
}
@media (min-width: 1200px) {
  .support-page .about-page__support {
    align-items: flex-start;
  }
}
.support-page .about-page__support__image {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .support-page .about-page__support__payment {
    align-self: flex-start;
  }
}
.support-page .about-page__support__payment__title {
  margin-top: 0;
}

.footer__social {
  background-color: #00A767;
  padding: 52px 0 64px;
}
.footer__social__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .footer__social__content {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer__main {
  background-color: #2E2F38;
  padding: 64px 0 40px;
}
.footer__main__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer__copyright {
  background-color: #2E2F38;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding: 32px 0;
}
.footer__copyright__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__copyright__content p {
  font-size: 16px;
  color: #EFF1F4;
  font-weight: normal;
  line-height: 1.75em;
}
.footer__investor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .footer__investor {
    flex-direction: row;
    justify-content: center;
  }
}
.footer__investor__text {
  max-width: 420px;
  margin: 32px 0;
  text-align: center;
  font-size: 14px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 768px) {
  .footer__investor__text {
    margin: 0 46px;
  }
}
.footer__investor__container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 200px;
  height: 200px;
}
.footer__investor img {
  max-width: 100%;
}
.footer__group {
  margin-right: 32px;
  margin-bottom: 32px;
  flex: 1 0 220px;
  overflow: hidden;
}
.footer__group__title {
  color: #FFF;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .footer__group__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.footer__group__list--bullets li {
  position: relative;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer__group__list--bullets li:hover::before {
  transform: scale(1.2);
}
.footer__group__list--bullets li::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00A767;
  position: absolute;
  left: 0;
  top: 0.7em;
  transition: transform 350ms;
}
.footer__group__list__item {
  font-size: 16px;
  color: #EFF1F4;
  font-weight: normal;
  line-height: 1.75em;
}
.footer__group__list__item:not(:last-child) {
  margin-bottom: 4px;
}
.footer__group__list__item a {
  color: inherit;
}
.footer__socials {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
}
@media (min-width: 992px) {
  .footer__socials {
    margin-right: 64px;
    margin-bottom: 0;
  }
}
.footer__socials__brand {
  margin-bottom: 24px;
}
.footer__socials__brand img {
  max-width: 100%;
}
.footer__socials__list {
  display: grid;
  grid-gap: 34px;
  grid-template-columns: repeat(auto-fit, 24px);
}
.footer__socials__list__item {
  width: 24px;
  height: 24px;
}
.footer__newsletter {
  max-width: 524px;
  flex: 1 1 0;
}
.footer__scroll_top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: flex-end;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  border-radius: 50%;
}
@media (min-width: 576px) {
  .footer__scroll_top {
    margin-left: auto;
  }
}
.footer__scroll_top:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 167, 103, 0.2);
  transition: box-shadow 350ms;
}
.footer__scroll_top:hover .footer__scroll_top__circle {
  animation: mouseoverScrollTop 800ms cubic-bezier(0.3, 0.1, 0, 1) forwards;
}
.footer__scroll_top__circle {
  stroke-dasharray: 402;
  stroke-dashoffset: 402;
  animation: mouseoutScrollTop 1.2s cubic-bezier(0.6, 0.5, 0, 1) forwards;
  pointer-events: none;
}
.footer__scroll_top__arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@keyframes mouseoverScrollTop {
  0% {
    stroke-dasharray: 0;
    transform: rotate(0);
    stroke-width: 2px;
  }
  15% {
    stroke-dasharray: 1, 3;
    stroke-width: 1px;
  }
  100% {
    stroke-dasharray: 1, 3;
    transform: rotate(35deg);
    stroke-width: 1px;
  }
}
@keyframes mouseoutScrollTop {
  from {
    transform: rotate(-45deg);
    stroke-dashoffset: 406;
  }
  to {
    transform: rotate(85deg);
    stroke-dashoffset: 0;
  }
}
.newsletter-form {
  display: flex;
  flex-direction: column;
}
.newsletter-form__title {
  color: #FFF;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .newsletter-form__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.newsletter-form__input {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #EFF1F4;
  border-radius: 8px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .newsletter-form__input {
    flex-direction: row;
  }
}
.newsletter-form__input__inner {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
  flex: 1 0 0;
  padding-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: none;
  background: none;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .newsletter-form__input__inner {
    margin-bottom: 0;
  }
}
.newsletter-form__input__inner:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 167, 103, 0.2);
  transition: box-shadow 350ms;
}
.newsletter-form__input__inner::placeholder {
  color: #828288;
}
.newsletter-form__input__submit {
  width: 100%;
}
@media (min-width: 768px) {
  .newsletter-form__input__submit {
    width: auto;
  }
}
.newsletter-form__agreement {
  color: #FFF;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

.hero-swiper {
  width: 100%;
}
.hero-swiper__slide {
  display: flex;
  padding: 136px 32px 74px;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}
@media (min-width: 992px) {
  .hero-swiper__slide {
    padding: 246px 32px 74px;
  }
}
.hero-swiper__bg, .hero-swiper__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-swiper__bg {
  object-fit: cover;
  z-index: -2;
}
.hero-swiper__overlay {
  background: rgba(0, 0, 0, 0.45);
  z-index: -1;
}
.hero-swiper__badge {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 64px;
  text-transform: uppercase;
  overflow: hidden;
  font-size: 14px;
  color: #FFF;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 992px) {
  .hero-swiper__badge {
    margin-bottom: 100px;
  }
}
.hero-swiper__badge__name {
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hero-swiper__badge__category {
  color: #FFF;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hero-swiper__content {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.hero-swiper__content__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 48px;
}
@media (min-width: 992px) {
  .hero-swiper__content__main {
    flex-direction: row;
  }
}
.hero-swiper__claim__title {
  margin-bottom: 24px;
  max-width: 890px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFF;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .hero-swiper__claim__title {
    font-size: 64px;
    line-height: 72px;
  }
}
.hero-swiper__claim__desc {
  color: #FFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 524px;
}
.hero-swiper__controls {
  display: flex;
  margin-top: 32px;
}
@media (min-width: 992px) {
  .hero-swiper__controls {
    margin-top: 0;
  }
}
.hero-swiper__controls__back {
  margin-right: 10px;
}
.hero-swiper__controls__back, .hero-swiper__controls__next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: none;
  cursor: pointer;
  outline: none;
  border: none;
  opacity: 0.75;
  transition: opacity 350ms;
}
.hero-swiper__controls__back:hover, .hero-swiper__controls__next:hover {
  opacity: 1;
}
.hero-swiper__like {
  background-color: white;
}
.hero-swiper__bar {
  padding: 24px;
  background-color: #FFF;
  border-radius: 4px;
  margin-top: auto;
}
@media (min-width: 992px) {
  .hero-swiper__bar {
    padding: 18px 64px;
  }
}
.hero-swiper__bar__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .hero-swiper__bar__inner {
    flex-direction: row;
  }
}
.hero-swiper__bar__progress {
  margin-bottom: 32px;
}
@media (min-width: 1200px) {
  .hero-swiper__bar__progress {
    margin-bottom: 0;
  }
}
.hero-swiper__bar__stats {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 0;
}
@media (min-width: 576px) {
  .hero-swiper__bar__stats {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .hero-swiper__bar__stats {
    flex-wrap: nowrap;
    align-items: center;
  }
}
.hero-swiper__bar__stats__item {
  display: flex;
  align-items: center;
  padding: 0 40px;
  overflow: hidden;
  margin-bottom: 32px;
}
@media (min-width: 1200px) {
  .hero-swiper__bar__stats__item {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .hero-swiper__bar__stats__item--user {
    width: 100%;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .hero-swiper__bar__stats__item--user {
    width: initial;
    max-width: 320px;
  }
}
.hero-swiper__bar__stats__item--user .hero-swiper__bar__stats__item__values {
  text-align: left;
}
@media (min-width: 992px) {
  .hero-swiper__bar__stats__item:not(:last-child) {
    border-right: solid 1px #EFF1F4;
  }
}
.hero-swiper__bar__stats__item__icon {
  width: 56px;
  height: 56px;
  margin-right: 15px;
  object-fit: contain;
  border-radius: 50%;
}
.hero-swiper__bar__stats__item__values {
  overflow: hidden;
  text-align: center;
}
@media (min-width: 1200px) {
  .hero-swiper__bar__stats__item__values {
    text-align: left;
  }
}
.hero-swiper__bar__stats__item__name {
  font-size: 16px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.hero-swiper__bar__stats__item__value {
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 576px) {
  .hero-swiper__bar__stats__item__value {
    font-size: 24px;
    line-height: 32px;
  }
}
.hero-swiper__bar__actions {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-bottom: -12px;
}
.hero-swiper__bar__actions > * {
  flex-grow: 1;
  margin-right: 12px;
  margin-bottom: 12px;
}

.campaigns-swiper__title {
  margin: 0 0 46px;
  color: #2E2F38;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .campaigns-swiper__title {
    font-size: 38px;
    line-height: 52px;
  }
}
@media (min-width: 992px) {
  .campaigns-swiper__title {
    margin: 0 110px 46px;
  }
}
.campaigns-swiper__swiper {
  display: flex;
  align-items: center;
}
.campaigns-swiper__swiper .swiper-slide {
  height: auto;
}
.campaigns-swiper__swiper .swiper-slide .campaign-card {
  height: 100%;
}
.campaigns-swiper__prev, .campaigns-swiper__next {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  width: 80px;
  height: 80px;
  background: none;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}
@media (min-width: 576px) {
  .campaigns-swiper__prev, .campaigns-swiper__next {
    display: block;
  }
}
.campaigns-swiper__prev:hover svg path, .campaigns-swiper__next:hover svg path {
  fill: #0C8068;
}
.campaigns-swiper__prev svg path, .campaigns-swiper__next svg path {
  transition: fill 350ms;
  fill: #00A767;
}
.campaigns-swiper__prev {
  margin-right: 16px;
}
@media (min-width: 768px) {
  .campaigns-swiper__prev {
    margin-right: 30px;
  }
}
.campaigns-swiper__next {
  margin-left: 16px;
}
@media (min-width: 768px) {
  .campaigns-swiper__next {
    margin-left: 30px;
  }
}
.campaigns-swiper__pagination {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
@media (min-width: 460px) {
  .campaigns-swiper__pagination {
    margin-bottom: 0;
  }
}
.campaigns-swiper__pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: #CFD1D7;
}
.campaigns-swiper__pagination .swiper-pagination-bullet:not(:last-child) {
  margin-right: 16px;
}
.campaigns-swiper__pagination .swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background-color: #00A767;
}
.campaigns-swiper__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 44px 0 0;
}
@media (min-width: 460px) {
  .campaigns-swiper__actions {
    flex-direction: row;
    justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .campaigns-swiper__actions {
    margin: 44px 110px 0;
    justify-content: space-between;
  }
}

.progress-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  position: relative;
}
.progress-circle__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.progress-circle__inner__text {
  font-size: 14px;
  color: #0C8068;
  font-weight: bold;
  line-height: 1.75em;
}
.progress-circle__inner__value {
  color: #00A767;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.progress-circle__circle__track {
  stroke: #EFF1F4;
}
.progress-circle__circle__progress {
  transform-origin: 50% 50%;
  transform: rotate(90deg);
}

.contact-form__row {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .contact-form__row {
    flex-direction: row;
  }
}
.contact-form__row .form-control {
  flex: 1 1 0;
}
.contact-form__row .form-control:not(:last-child) {
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .contact-form__row .form-control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
.contact-form__message {
  margin-top: 20px;
  margin-bottom: 20px;
}
.contact-form__agreement {
  display: flex;
  align-items: center;
  max-width: 320px;
  margin-bottom: 40px;
}
.contact-form__submit {
  width: 100%;
}

.contact-details__title {
  margin-bottom: 64px;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.contact-details__group:not(:last-child) {
  margin-bottom: 42px;
}
.contact-details__subgroup:not(:last-child) {
  margin-bottom: 28px;
}
.contact-details__subgroup__title {
  margin-bottom: 10px;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.contact-details__socials {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(24px, 1fr));
}
.contact-details__company-adress {
  font-size: 14px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.contact-details__company-info {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  font-size: 14px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.contact-details__company-info li a {
  color: inherit;
}
.contact-details__contact-btn {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.contact-details__contact-btn__icon {
  margin-right: 12px;
}

.create-campaign-banner {
  padding: 52px 32px 36px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .create-campaign-banner {
    padding: 126px 86px;
  }
}
@media (min-width: 1200px) {
  .create-campaign-banner {
    padding: 126px 264px 126px 86px;
    background: #FFF url("/img/create-campaign-banner-bg.png") no-repeat right center;
    background-size: auto 100%;
  }
}
.create-campaign-banner__text {
  margin-bottom: 34px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .create-campaign-banner__text {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 576px) {
  .create-campaign-banner__text {
    color: #2E2F38;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
  }
}
@media (min-width: 576px) and (min-width: 576px) {
  .create-campaign-banner__text {
    font-size: 38px;
    line-height: 52px;
  }
}
.create-campaign-banner__text--highlight {
  color: #00A767;
}
.create-campaign-banner__actions {
  display: flex;
  flex-wrap: wrap;
}
.create-campaign-banner__actions > * {
  margin-right: 24px;
  margin-bottom: 24px;
}

.categories-masonry__title {
  color: #00A767;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .categories-masonry__title {
    font-size: 64px;
    line-height: 72px;
  }
}
.categories-masonry__desc {
  margin-top: 24px;
  max-width: 760px;
  font-size: 20px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.categories-masonry__grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.categories-masonry__tile {
  flex: 1 1 380px;
  display: block;
  height: 260px;
  padding: 30px 26px 30px 30px;
  position: relative;
  z-index: 0;
}
.categories-masonry__tile:hover {
  text-decoration: none;
}
.categories-masonry__tile:hover .categories-masonry__tile__overlay {
  background-color: #045555;
}
.categories-masonry__tile:hover .categories-masonry__tile__name span {
  top: 100%;
  transform: translateY(-100%);
}
.categories-masonry__tile:hover .categories-masonry__tile__count {
  opacity: 1;
  transform: translateY(0);
}
.categories-masonry__tile:hover .categories-masonry__tile__button circle {
  fill: rgba(255, 255, 255, 0.3);
  stroke: rgba(0, 0, 0, 0);
}
.categories-masonry__tile__bg, .categories-masonry__tile__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.categories-masonry__tile__bg {
  object-fit: cover;
  z-index: -2;
}
.categories-masonry__tile__overlay {
  background-color: rgba(0, 0, 0, 0.15);
  z-index: -1;
  transition: background-color 350ms;
}
.categories-masonry__tile__content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.categories-masonry__tile__name {
  flex-grow: 1;
  max-width: calc(100% - 64px);
  margin-bottom: 8px;
  position: relative;
  color: #FFF;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 576px) {
  .categories-masonry__tile__name {
    font-size: 24px;
    line-height: 32px;
  }
}
.categories-masonry__tile__name span {
  position: absolute;
  left: 0;
  top: 0;
  transition: top 350ms, transform 350ms;
}
.categories-masonry__tile__count {
  color: #EFF1F4;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 350ms, transform 350ms;
  transform: translateY(75%);
}
.categories-masonry__tile__button {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}
.categories-masonry__tile__button circle {
  stroke: #FFF;
  fill: rgba(0, 0, 0, 0);
  transition: fill 350ms, stroke 350ms;
}

.about-platform__upper {
  position: relative;
  height: 420px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .about-platform__upper {
    height: 670px;
  }
}
.about-platform__upper .simple-parallax-initialized {
  display: flex;
  justify-content: center;
}
.about-platform__upper__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 64px 24px;
}
@media (min-width: 768px) {
  .about-platform__upper__content {
    padding: 78px 110px;
  }
}
.about-platform__upper__content::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.about-platform__upper__title {
  color: #FFF;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
  margin-top: auto;
  margin-bottom: 32px;
  max-width: 480px;
}
@media (min-width: 576px) {
  .about-platform__upper__title {
    font-size: 38px;
    line-height: 52px;
  }
}
.about-platform__lower {
  display: flex;
  flex-direction: column;
  padding: 98px 24px 0 24px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .about-platform__lower {
    padding: 98px 64px 0 110px;
  }
}
@media (min-width: 1200px) {
  .about-platform__lower {
    flex-direction: row;
  }
}
.about-platform__lower__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 524px;
  margin-bottom: 82px;
}
.about-platform__lower__title {
  color: #2E2F38;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .about-platform__lower__title {
    font-size: 38px;
    line-height: 52px;
  }
}
.about-platform__lower__text {
  font-size: 18px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
  margin-bottom: 56px;
}
.about-platform__lower__more {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.about-platform__lower__more svg {
  margin-left: 8px;
}
.about-platform__lower__image img {
  max-width: 100%;
  display: block;
}

.platform-details__header {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .platform-details__header {
    flex-direction: row;
    align-items: flex-end;
  }
}
.platform-details__header__image {
  flex: 1 1 0;
}
@media (min-width: 1200px) {
  .platform-details__header__image {
    max-width: 854px;
  }
}
.platform-details__header__image img {
  display: block;
  max-width: 100%;
  width: 100%;
}
.platform-details__header__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .platform-details__header__content {
    flex: 1 1 0;
  }
}
.platform-details__header__content__inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px 64px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .platform-details__header__content__inner {
    padding: 44px 52px 100px;
  }
}
.platform-details__header__title {
  padding: 42px 24px 0px;
  background-color: #FFF;
  color: #2E2F38;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .platform-details__header__title {
    font-size: 64px;
    line-height: 72px;
  }
}
@media (min-width: 768px) {
  .platform-details__header__title {
    padding: 82px 52px 44px;
  }
}
@media (min-width: 1200px) {
  .platform-details__header__title {
    background-color: rgba(0, 0, 0, 0);
  }
}
.platform-details__header__desc {
  margin-bottom: 48px;
  max-width: 460px;
  font-size: 20px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.platform-details__header__more {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.platform-details__header__more svg {
  margin-left: 8px;
}
.platform-details__header__more svg path {
  fill: #00A767;
}
.platform-details__content {
  padding: 64px 24px;
  background-color: #FFF;
}
@media (min-width: 992px) {
  .platform-details__content {
    padding: 102px 110px 130px;
  }
}

.details-tabs__tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  padding: 1px;
}
@media (min-width: 992px) {
  .details-tabs__tabs {
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  }
}
.details-tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin: -1px;
  cursor: pointer;
  outline: none;
  border: solid 1px #EFF1F4;
  background-color: #FFF;
  color: #2E2F38;
  transition: color 350ms, background-color 350ms;
}
@media (min-width: 576px) {
  .details-tabs__tab {
    padding: 42px;
  }
}
.details-tabs__tab--active {
  background-color: #00A767;
  color: #FFF;
}
.details-tabs__tab:not(.details-tabs__tab--active):focus, .details-tabs__tab:not(.details-tabs__tab--active):hover {
  background-color: rgba(0, 167, 103, 0.05);
}
.details-tabs__tab:first-child {
  border-radius: 2px 0 0 2px;
}
.details-tabs__tab:last-child {
  border-radius: 0 2px 2px 0;
}
.details-tabs__tab__image {
  display: block;
  margin-bottom: 6px;
  width: 42px;
  height: 42px;
  object-fit: contain;
}
@media (min-width: 576px) {
  .details-tabs__tab__image {
    height: 64px;
    width: 64px;
  }
}
.details-tabs__tab__label {
  font-size: 12px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
  color: currentColor;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .details-tabs__tab__label {
    font-size: 14px;
  }
}
.details-tabs__content {
  margin-top: 42px;
  max-width: 760px;
  font-size: 18px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .details-tabs__content {
    font-size: 24px;
  }
}

.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.auth-modal__overlay {
  position: fixed;
  z-index: 51;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}
.auth-modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 52;
}
@media (min-width: 576px) {
  .auth-modal__wrapper {
    padding: 56px 24px;
  }
}
.auth-modal__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 969px;
  max-height: calc(100vh - 120px);
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
  position: relative;
}
@media (min-width: 576px) {
  .auth-modal__main {
    flex-direction: row;
    border-radius: 16px;
  }
}
.auth-modal__aside {
  flex: 0 1 0;
  padding: 82px 50px;
  position: relative;
  z-index: 0;
  display: none;
}
@media (min-width: 768px) {
  .auth-modal__aside {
    display: flex;
  }
}
@media (min-width: 992px) {
  .auth-modal__aside {
    flex: 0 1 340px;
  }
}
.auth-modal__aside__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}
.auth-modal__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 58px 24px 58px;
  overflow: auto;
}
@media (min-width: 992px) {
  .auth-modal__content {
    padding: 80px 94px 58px;
  }
}
.auth-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  z-index: 1;
  border-radius: 8px;
  background-color: #EFF1F4;
  border: none;
  cursor: pointer;
  margin-left: auto;
}
@media (min-width: 768px) {
  .auth-modal__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
.auth-modal__close svg {
  display: block;
  pointer-events: none;
}
.auth-modal__title {
  color: #00A767;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 4px;
}
@media (min-width: 576px) {
  .auth-modal__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.auth-modal__desc {
  font-size: 12px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.auth-modal__claim {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.auth-modal__claim button {
  font-size: 16px;
  color: #3FA2F7;
  font-weight: normal;
  line-height: 1.75em;
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 6px;
}
.auth-modal__claim button:hover {
  text-decoration: underline;
}
.auth-modal__form__inputs__item:not(:last-child) {
  margin-bottom: 16px;
}
.auth-modal__form__inputs__item__desc {
  margin-top: 10px;
  color: #828288;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
.auth-modal__form__agreements {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin: 32px 0;
}
.auth-modal__form__agreements .checkbox__label a {
  color: #00A767;
}
.auth-modal__form__remember {
  font-size: 16px;
  color: #3FA2F7;
  font-weight: normal;
  line-height: 1.75em;
}
.auth-modal__form__submit {
  display: flex;
  flex-direction: column;
}
.auth-modal__form__submit__desc {
  margin-top: 10px;
  text-align: center;
  color: #828288;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
.auth-modal__form__submit__desc a {
  color: #00A767;
}
.auth-modal__actions {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: auto;
}

.register-modal__desc {
  margin-bottom: 12px;
}
.register-modal__form {
  margin-top: 12px;
}
.register-modal__brand {
  margin-top: auto;
}

.login-modal__form {
  margin: 36px 0;
}
.login-modal__submit {
  margin-top: 24px;
}

.remember-password-modal__form {
  margin-top: 36px;
}
.remember-password-modal__submit {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 36px;
}

.faq-card {
  border: solid 1px #EFF1F4;
  border-radius: 4px;
  background-color: #FFF;
}
.faq-card--active .faq-card__header__arrow {
  transform: rotate(180deg);
}
.faq-card--active .faq-card__content {
  display: block;
}
.faq-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 12px;
  cursor: pointer;
}
.faq-card__header__title {
  word-break: break-word;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.faq-card__header__arrow {
  flex-shrink: 0;
  margin-left: 12px;
  transition: transform 150ms;
}
.faq-card__content {
  padding: 0 12px 32px;
  margin-top: -12px;
  display: none;
}
.faq-card__content__text {
  word-break: break-word;
  font-size: 14px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}

.jumbotron__header {
  padding: 64px 32px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #FFF;
}
@media (min-width: 576px) {
  .jumbotron__header {
    padding: 90px 32px;
  }
}
@media (min-width: 992px) {
  .jumbotron__header {
    padding: 90px 64px;
  }
}
@media (min-width: 1200px) {
  .jumbotron__header {
    padding: 90px 112px;
  }
}
.jumbotron__header__title {
  color: #2E2F38;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .jumbotron__header__title {
    font-size: 64px;
    line-height: 72px;
  }
}
.jumbotron__header__subtitle {
  margin-top: 18px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: normal;
}
@media (min-width: 576px) {
  .jumbotron__header__subtitle {
    font-size: 32px;
    line-height: 40px;
  }
}
.jumbotron__header__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  object-fit: cover;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .jumbotron__header__bg {
    left: 210px;
    opacity: 1;
  }
}
.jumbotron__content {
  background-color: #FFF;
  padding: 88px 32px;
}
@media (min-width: 992px) {
  .jumbotron__content {
    padding: 88px 64px;
  }
}
@media (min-width: 1200px) {
  .jumbotron__content {
    padding: 88px 224px 112px 112px;
  }
}

.campaigns-filters {
  padding: 8px 12px;
  display: block;
  flex-wrap: wrap;
  overflow: hidden;
}
@media (min-width: 768px) {
  .campaigns-filters {
    display: flex;
  }
}
@media (min-width: 992px) {
  .campaigns-filters {
    display: block;
  }
}
.campaigns-filters__group {
  padding: 16px 0;
}
.campaigns-filters__group--full {
  width: 100%;
}
.campaigns-filters__group--col {
  flex: 1 1 0;
}
.campaigns-filters__group:not(:last-child) {
  border-bottom: solid 1px #EFF1F4;
}
@media (min-width: 768px) {
  .campaigns-filters__group:not(:last-child) {
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .campaigns-filters__group:not(:last-child) {
    border-bottom: solid 1px #EFF1F4;
  }
}
.campaigns-filters__group__header {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: margin-bottom 350ms;
}
.campaigns-filters__group__header.active {
  margin-bottom: 12px;
}
.campaigns-filters__group__header.active .campaigns-filters__group__header__arrow {
  transform: rotate(180deg);
}
.campaigns-filters__group__header__icon {
  margin-right: 12px;
}
.campaigns-filters__group__header__title {
  margin-right: 12px;
  font-size: 14px;
  color: #00A767;
  font-weight: bold;
  line-height: 1.75em;
  text-transform: uppercase;
}
.campaigns-filters__group__header__arrow {
  margin-left: auto;
  transition: transform 350ms;
}
@media (min-width: 768px) {
  .campaigns-filters__group__header__arrow {
    margin-right: 16px;
  }
}
@media (min-width: 992px) {
  .campaigns-filters__group__header__arrow {
    margin-right: 0;
  }
}
.campaigns-filters__group__search {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
@media (min-width: 768px) {
  .campaigns-filters__group__search {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 26px;
  }
}
@media (min-width: 992px) {
  .campaigns-filters__group__search {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.campaigns-filters__group__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}
.campaigns-filters__group__items .checkbox {
  overflow: hidden;
}
.campaigns-filters__group__items .checkbox__label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.campaigns-filters__topbar {
  display: flex;
  justify-content: space-between;
}
.campaigns-filters__topbar__title {
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaigns-filters__topbar__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.campaigns-filters__topbar__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 60px;
  background-color: #EFF1F4;
  cursor: pointer;
  border: none;
  outline: none;
}
.campaigns-filters__topbar__clear:hover {
  text-decoration: none;
}
.campaigns-filters__topbar__clear svg {
  margin-left: 4px;
}

.campaign-details__content {
  padding: 40px 24px;
  margin-bottom: 32px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .campaign-details__content {
    padding: 40px 60px;
  }
}
.campaign-details__content__title {
  margin-bottom: 16px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__content__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-details__image {
  position: relative;
  width: 100%;
  padding-top: 75%;
}
.campaign-details__image::after {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 0 40px 0 0;
  background-color: #00A767;
  position: absolute;
  left: 24px;
  bottom: -40px;
}
@media (min-width: 768px) {
  .campaign-details__image::after {
    left: 60px;
  }
}
.campaign-details__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
}
.campaign-details__title {
  padding: 66px 24px 24px;
  background-color: #FFF;
  word-break: break-word;
  color: #2E2F38;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .campaign-details__title {
    font-size: 38px;
    line-height: 52px;
  }
}
@media (min-width: 768px) {
  .campaign-details__title {
    padding: 66px 60px 24px;
  }
}
.campaign-details__tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid 1px #EFF1F4;
  padding: 24px 24px 0;
  background-color: #FAFBFD;
  position: relative;
}
@media (min-width: 768px) {
  .campaign-details__tabs {
    padding: 24px 60px 0;
  }
}
@media (min-width: 992px) {
  .campaign-details__tabs {
    flex-direction: row;
    align-items: flex-end;
  }
}
.campaign-details__tabs__scroll-target {
  position: absolute;
  top: -160px;
}
.campaign-details__tabs__item {
  padding: 12px;
  transition: color 350ms;
  position: relative;
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 992px) {
  .campaign-details__tabs__item {
    padding: 12px 12px 24px;
  }
}
.campaign-details__tabs__item:not(:last-child) {
  margin-right: 20px;
}
.campaign-details__tabs__item.current {
  color: #00A767;
}
.campaign-details__tabs__item.current::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3px;
  height: 100%;
  background-color: #00A767;
}
@media (min-width: 992px) {
  .campaign-details__tabs__item.current::after {
    width: 100%;
    height: 3px;
  }
}
.campaign-details__tabs__item:hover {
  color: #00A767;
  text-decoration: none;
}
.campaign-details__description {
  background-color: #FFF;
  word-break: break-word;
}
.campaign-details__description h3 {
  margin-bottom: 16px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__description h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-details__description p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__description strong {
  font-weight: bold;
}
.campaign-details__description ul,
.campaign-details__description ol {
  margin-bottom: 16px;
  padding-left: 18px;
}
.campaign-details__description ul li,
.campaign-details__description ol li {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__description ul li:not(:last-child),
.campaign-details__description ol li:not(:last-child) {
  margin-bottom: 16px;
}
.campaign-details__description ol {
  list-style-type: decimal;
}
.campaign-details__description ul {
  list-style-type: disc;
}
.campaign-details__description figure {
  margin-bottom: 16px;
}
.campaign-details__description .image {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .campaign-details__description .image {
    margin-left: -60px;
    margin-right: -60px;
  }
}
.campaign-details__description .image img {
  display: block;
  width: 100%;
}
.campaign-details__updates {
  display: grid;
  grid-gap: 20px;
}
.campaign-details__updates__item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border: solid 1px #EFF1F4;
  border-radius: 4px;
}
.campaign-details__updates__item__avatar {
  margin-right: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .campaign-details__updates__item__avatar {
    width: 42px;
    height: 42px;
  }
}
.campaign-details__updates__item__content__details {
  display: flex;
  align-items: center;
}
.campaign-details__updates__item__content__details__author {
  margin-right: 8px;
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.campaign-details__updates__item__content__details__date {
  color: #828288;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
.campaign-details__updates__item__content__title {
  word-break: break-word;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.campaign-details__updates__item__content__desc h3 {
  margin-bottom: 16px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__updates__item__content__desc h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-details__updates__item__content__desc p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__updates__item__content__desc strong {
  font-weight: bold;
}
.campaign-details__updates__item__content__desc ul,
.campaign-details__updates__item__content__desc ol {
  margin-bottom: 16px;
  padding-left: 18px;
}
.campaign-details__updates__item__content__desc ul li,
.campaign-details__updates__item__content__desc ol li {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__updates__item__content__desc ul li:not(:last-child),
.campaign-details__updates__item__content__desc ol li:not(:last-child) {
  margin-bottom: 16px;
}
.campaign-details__updates__item__content__desc ol {
  list-style-type: decimal;
}
.campaign-details__updates__item__content__desc ul {
  list-style-type: disc;
}
.campaign-details__updates__item__content__desc figure {
  margin-bottom: 16px;
}
.campaign-details__updates__item__content__desc h3 {
  word-break: break-word;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.campaign-details__updates__empty {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__faqs {
  display: grid;
  grid-gap: 10px;
}
.campaign-details__faqs__empty {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__goals-timeline {
  margin-bottom: 30px;
  padding: 46px 24px;
  background-color: #FAFBFD;
  border-radius: 8px;
}
.campaign-details__goals {
  display: grid;
  grid-gap: 40px;
}
.campaign-details__goals__item__title {
  margin-bottom: 12px;
  word-break: break-word;
  color: #2E2F38;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.campaign-details__goals__item__amount {
  margin-bottom: 12px;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__goals__item__amount {
    font-size: 24px;
    line-height: 32px;
  }
}
.campaign-details__goals__item__desc {
  word-break: break-word;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__goals__empty {
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__payments {
  margin-bottom: 20px;
  background-color: #FFF;
  border-top: solid 1px #EFF1F4;
}
.campaign-details__payments__header {
  padding: 36px 24px 0;
  border-bottom: solid 1px #EFF1F4;
}
@media (min-width: 768px) {
  .campaign-details__payments__header {
    padding: 36px 60px 0;
  }
}
.campaign-details__payments__header__title {
  margin-bottom: 12px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__payments__header__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-details__payments__header__tabs {
  display: flex;
}
.campaign-details__payments__header__tabs__item {
  padding: 12px 14px 12px 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: color 350ms;
  text-align: left;
  font-size: 12px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .campaign-details__payments__header__tabs__item {
    padding: 14px 42px 14px 0;
    font-size: 14px;
    color: #828288;
    font-weight: bold;
    line-height: 1.75em;
  }
}
.campaign-details__payments__header__tabs__item:not(:last-child) {
  margin-right: 32px;
}
.campaign-details__payments__header__tabs__item:hover {
  color: #00A767;
}
.campaign-details__payments__header__tabs__item--active {
  color: #00A767;
}
.campaign-details__payments__header__tabs__item--active::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #00A767;
}
.campaign-details__payments__content {
  padding: 20px 24px 54px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .campaign-details__payments__content {
    padding: 20px 60px 54px;
  }
}
.campaign-details__payments__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px 20px;
}
@media (min-width: 768px) {
  .campaign-details__payments__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.campaign-details__payments__list__item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #FFF;
  border: solid 1px #EFF1F4;
  overflow: hidden;
}
.campaign-details__payments__list__item__avatar {
  flex-shrink: 0;
  margin-right: 18px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .campaign-details__payments__list__item__avatar {
    width: 42px;
    height: 42px;
  }
}
.campaign-details__payments__list__item__details {
  margin-right: 12px;
  overflow: hidden;
}
.campaign-details__payments__list__item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .campaign-details__payments__list__item__name {
    color: #2E2F38;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
}
.campaign-details__payments__list__item__date {
  font-size: 12px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .campaign-details__payments__list__item__date {
    font-size: 14px;
    color: #828288;
    font-weight: normal;
    line-height: 1.75em;
  }
}
.campaign-details__payments__list__item__amount {
  margin-left: auto;
  white-space: nowrap;
  color: #00A767;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.campaign-details__payments__list__empty {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__payments__list__empty:not(:only-child) {
  display: none;
}
.campaign-details__payments__load-more {
  margin-top: 24px;
}
.campaign-details__comments {
  background-color: #FFF;
}
.campaign-details__comments__header {
  padding: 36px 24px 20px;
  border-bottom: solid 1px #EFF1F4;
}
@media (min-width: 768px) {
  .campaign-details__comments__header {
    padding: 36px 60px 20px;
  }
}
.campaign-details__comments__header__title {
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__comments__header__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-details__comments__content {
  padding: 20px 24px 54px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .campaign-details__comments__content {
    padding: 20px 60px 54px;
  }
}
.campaign-details__comments__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}
.campaign-details__comments__list__item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #EFF1F4;
  overflow: hidden;
}
.campaign-details__comments__list__item__details {
  display: flex;
  align-items: flex-start;
}
.campaign-details__comments__list__item__avatar {
  flex-shrink: 0;
  margin-right: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .campaign-details__comments__list__item__avatar {
    width: 42px;
    height: 42px;
  }
}
.campaign-details__comments__list__item__datename {
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .campaign-details__comments__list__item__datename {
    flex-direction: row;
    align-items: center;
  }
}
.campaign-details__comments__list__item__name {
  margin-right: 8px;
  MAX-WIDTH: 220px;
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25em;
}
@media (min-width: 576px) {
  .campaign-details__comments__list__item__name {
    line-height: 1.75em;
  }
}
.campaign-details__comments__list__item__date {
  color: #828288;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
@media (min-width: 576px) {
  .campaign-details__comments__list__item__date {
    line-height: 1.75rem;
  }
}
.campaign-details__comments__list__item__aside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}
.campaign-details__comments__list__item__amount {
  white-space: nowrap;
  color: #00A767;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  line-height: 1.75rem;
}
.campaign-details__comments__list__item__delete {
  margin-top: 8px;
}
.campaign-details__comments__list__item__description {
  margin-top: 12px;
  word-break: break-word;
  font-size: 14px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
@media (min-width: 576px) {
  .campaign-details__comments__list__item__description {
    margin-top: -16px;
    margin-right: 82px;
    margin-left: 54px;
  }
}
.campaign-details__comments__list__empty {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-details__comments__list__empty:not(:only-child) {
  display: none;
}
.campaign-details__comments__load-more {
  margin-top: 24px;
}
.campaign-details__payment-card {
  margin-top: 20px;
}
.campaign-details__pending {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px 56px;
  margin-bottom: 48px;
  border-radius: 2px;
  background-color: #FFF;
}
@media (min-width: 768px) {
  .campaign-details__pending {
    padding: 46px 90px 96px 80px;
  }
}
.campaign-details__pending__image {
  margin-bottom: 56px;
  max-width: 100%;
}
.campaign-details__pending__title {
  margin-bottom: 34px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-details__pending__title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (min-width: 992px) and (min-height: 820px) {
  .campaign-aside--sticky {
    position: sticky;
    top: 136px;
  }
}
.campaign-aside__edit-button {
  margin-bottom: 8px;
  width: 100%;
}
.campaign-aside__promote-button {
  margin-bottom: 16px;
  width: 100%;
}
.campaign-aside__basic {
  padding: 16px;
  margin-bottom: 4px;
  background-color: #FFF;
  border-radius: 2px;
}
.campaign-aside__basic__badges {
  display: flex;
  margin-bottom: 12px;
  overflow: hidden;
}
.campaign-aside__basic__badges__item {
  padding: 4px 16px;
  border-radius: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.campaign-aside__basic__badges__item--category {
  background-color: #EDF7FF;
  color: #3FA2F7;
}
.campaign-aside__basic__voivodeship {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-aside__basic__voivodeship__icon {
  flex-shrink: 0;
  margin-top: -2px;
  margin-right: 4px;
}
.campaign-aside__basic__amount {
  margin-bottom: 12px;
}
.campaign-aside__basic__amount__left {
  color: #00A767;
  font-size: 28px;
  line-height: 36px;
  font-weight: normal;
}
@media (min-width: 576px) {
  .campaign-aside__basic__amount__left {
    font-size: 38px;
    line-height: 52px;
  }
}
.campaign-aside__basic__amount__goal {
  font-size: 18px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-aside__basic__status {
  margin: 8px 0;
  color: #828288;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.campaign-aside__basic__actions {
  display: flex;
  margin-top: 8px;
}
@media (max-width: 576px) {
  .campaign-aside__basic__actions__support, .campaign-aside__basic__actions__like {
    height: 56px;
  }
}
.campaign-aside__basic__actions__support {
  flex-grow: 1;
  margin-right: 12px;
}
.campaign-aside__basic__actions__like {
  flex-shrink: 0;
}
.campaign-aside__stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  padding: 16px;
  background-color: #FFF;
  border-radius: 2px;
  margin-bottom: 4px;
}
.campaign-aside__stats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: #FAFBFD;
}
.campaign-aside__stats__item__icon {
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .campaign-aside__stats__item__icon {
    width: 48px;
    height: 48px;
  }
}
.campaign-aside__stats__item__icon path {
  fill: #00A767;
}
.campaign-aside__stats__item__value {
  margin-bottom: 4px;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-aside__stats__item__value {
    font-size: 24px;
    line-height: 32px;
  }
}
.campaign-aside__stats__item__name {
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.campaign-aside__author {
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 2px;
  padding: 16px 24px;
  margin-bottom: 4px;
}
.campaign-aside__author__avatar {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .campaign-aside__author__avatar {
    width: 56px;
    height: 56px;
  }
}
.campaign-aside__author__details {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  overflow: hidden;
}
.campaign-aside__author__details__label {
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.campaign-aside__author__details__name {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #3FA2F7;
  font-weight: normal;
  line-height: 1.75em;
}
@media (min-width: 460px) {
  .campaign-aside__author__details__name {
    color: #3FA2F7;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
  }
}
@media (min-width: 460px) and (min-width: 576px) {
  .campaign-aside__author__details__name {
    font-size: 24px;
    line-height: 32px;
  }
}
.campaign-aside__author__details__name:hover {
  text-decoration: none;
}
.campaign-aside__author__badge {
  margin-right: 12px;
}
.campaign-aside__author__info {
  margin-left: auto;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaign-aside__author__info:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 167, 103, 0.2);
  transition: box-shadow 350ms;
}
.campaign-aside__author__info svg path {
  fill: #00A767;
}
.campaign-aside__share {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-bottom: 12px;
}
.campaign-aside__share__icon {
  margin-right: 8px;
}
.campaign-aside__share__text {
  font-size: 16px;
  color: #3FA2F7;
  font-weight: bold;
  line-height: 1.75em;
}
.campaign-aside__rewards {
  display: grid;
  grid-gap: 4px;
}
.campaign-aside__rewards__item {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  padding: 16px;
  border-radius: 2px;
}
.campaign-aside__rewards__item__details {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.campaign-aside__rewards__item__amount {
  margin-bottom: 8px;
  color: #00A767;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .campaign-aside__rewards__item__amount {
    font-size: 32px;
    line-height: 40px;
  }
}
.campaign-aside__rewards__item__supporters {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.campaign-aside__rewards__item__supporters__icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.campaign-aside__rewards__item__supporters__label {
  margin-right: 8px;
  font-size: 14px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-aside__rewards__item__supporters__count {
  font-size: 14px;
  color: #3FA2F7;
  font-weight: bold;
  line-height: 1.75em;
}
.campaign-aside__rewards__item__description {
  word-break: break-word;
  font-size: 14px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-aside__rewards__item__select {
  margin-top: 8px;
}
.campaign-aside__rewards__item__date {
  display: flex;
  margin-bottom: 8px;
}
.campaign-aside__rewards__item__date__label {
  margin-right: 4px;
  font-size: 14px;
  color: #3FA2F7;
  font-weight: bold;
  line-height: 1.75em;
}
.campaign-aside__rewards__item__date__value {
  font-size: 14px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.campaign-aside__support {
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
}

.goals-timeline__track {
  position: relative;
  background-color: #EFF1F4;
  height: 6px;
  border-radius: 100px;
}
.goals-timeline__track__progress {
  display: block;
  background: linear-gradient(90deg, #8effd4 0%, #00A767 100%);
  height: 100%;
  border-radius: 100px;
}
.goals-timeline__track__point {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #EFF1F4;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.goals-timeline__track__point--hidden {
  opacity: 0;
  pointer-events: none;
}
.goals-timeline__track__point:hover .goals-timeline__track__point__label--group span {
  display: block;
}
.goals-timeline__track__point:hover .goals-timeline__track__point__label--group::after {
  display: none;
}
.goals-timeline__track__point::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 75%);
}
.goals-timeline__track__point__label {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: calc(100% + 10px);
  white-space: nowrap;
  font-size: 10px;
  color: #828288;
  font-weight: bold;
}
@media (min-width: 576px) {
  .goals-timeline__track__point__label {
    font-size: 12px;
  }
}
.goals-timeline__track__point__label--group {
  padding: 0 6px 6px;
  border-radius: 4px;
  background-color: #FAFBFD;
}
.goals-timeline__track__point__label--group span:not(:first-child) {
  margin-top: 4px;
  display: none;
}
.goals-timeline__track__point__label--group::after {
  content: "...";
}
.goals-timeline__track__point--current {
  background-color: #FFF;
  border: solid 3px #00A767;
}
.goals-timeline__track__point--current .goals-timeline__track__point__label {
  color: #00A767;
  bottom: auto;
  top: -22px;
}
.goals-timeline__track__point--passed {
  background-color: #FFF;
  border: solid 1px #00A767;
}

@media (min-width: 768px) {
  .profile-details-modal .basic-modal__main {
    max-width: 640px;
  }
}
.profile-details-modal .basic-modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(130, 130, 136, 0.2);
  border-radius: 4px;
}
.profile-details-modal .basic-modal__close svg path {
  fill: #FFF;
}
.profile-details-modal__header {
  min-height: 132px;
  margin-bottom: 50px;
  position: relative;
  background-image: url("/img/modal-header-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.profile-details-modal__header__avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  left: 30px;
}
.profile-details-modal__content {
  padding: 0 30px 20px;
}
.profile-details-modal__profile-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .profile-details-modal__profile-data {
    flex-direction: row;
  }
}
.profile-details-modal__group__label {
  margin-bottom: 4px;
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.profile-details-modal__name {
  display: flex;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .profile-details-modal__name {
    margin-right: 24px;
    margin-bottom: 0;
  }
}
.profile-details-modal__name__value {
  margin-right: 12px;
  word-break: break-word;
  color: #3FA2F7;
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
}
@media (min-width: 576px) {
  .profile-details-modal__name__value {
    font-size: 24px;
    line-height: 32px;
  }
}
.profile-details-modal__name__badge {
  pointer-events: none;
}
.profile-details-modal__name__badge path {
  fill: #3FA2F7;
}
.profile-details-modal__socials {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  min-width: 200px;
}
.profile-details-modal__socials__empty {
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
.profile-details-modal__socials__empty:not(:only-child) {
  display: none;
}
.profile-details-modal__stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-bottom: 24px;
}
.profile-details-modal__stats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: #FAFBFD;
}
.profile-details-modal__stats__item__icon {
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .profile-details-modal__stats__item__icon {
    width: 48px;
    height: 48px;
  }
}
.profile-details-modal__stats__item__icon path {
  fill: #00A767;
}
.profile-details-modal__stats__item__value {
  margin-bottom: 4px;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .profile-details-modal__stats__item__value {
    font-size: 24px;
    line-height: 32px;
  }
}
.profile-details-modal__stats__item__name {
  font-size: 14px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.profile-details-modal__about {
  margin-bottom: 24px;
}
.profile-details-modal__about__title {
  margin-bottom: 4px;
  font-size: 16px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.profile-details-modal__about__desc {
  word-break: break-word;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.profile-details-modal__campaigns {
  display: grid;
  margin-bottom: 22px;
}
.profile-details-modal__campaigns__title {
  margin-bottom: 6px;
  font-size: 16px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.profile-details-modal__campaigns__list {
  display: grid;
  grid-gap: 8px;
}
.profile-details-modal__campaigns__list a {
  word-break: break-word;
  font-size: 16px;
  color: #3FA2F7;
  font-weight: normal;
  line-height: 1.75em;
}
.profile-details-modal__back {
  display: flex;
  justify-content: center;
  background-color: #FFF;
  position: sticky;
  bottom: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .share-campaign-modal .basic-modal__main {
    max-width: 640px;
  }
}
.share-campaign-modal .basic-modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(130, 130, 136, 0.2);
  border-radius: 4px;
}
.share-campaign-modal .basic-modal__close svg path {
  fill: #FFF;
}
.share-campaign-modal__header {
  min-height: 132px;
  position: relative;
  background-image: url("/img/modal-header-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.share-campaign-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px;
}
.share-campaign-modal__title {
  margin-bottom: 16px;
  text-align: center;
  color: #00A767;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .share-campaign-modal__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.share-campaign-modal__desc {
  margin-bottom: 38px;
  max-width: 320px;
  text-align: center;
  font-size: 18px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.share-campaign-modal__socials {
  margin-bottom: 32px;
}

.payment-card {
  padding: 40px 24px;
  background-color: #FFF;
  border-radius: 2px;
  position: relative;
}
@media (min-width: 768px) {
  .payment-card {
    padding: 40px 60px;
  }
}
.payment-card__scroll-anchor {
  position: absolute;
  top: -120px;
}
.payment-card__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 14px 24px;
  margin-bottom: 30px;
}
.payment-card__header__title {
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-card__header__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-card__header__donors {
  display: flex;
  align-items: center;
}
.payment-card__header__donors__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.payment-card__header__donors__label {
  margin-right: 8px;
  font-size: 14px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.payment-card__header__donors__value {
  font-size: 14px;
  color: #3FA2F7;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-card__content {
  padding: 36px 0 0;
}
.payment-card__auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 32px 0;
  margin: 0 auto;
}
.payment-card__auth__img {
  margin-bottom: 40px;
  max-width: 240px;
}
.payment-card__auth__title {
  max-width: 440px;
  text-align: center;
  margin-bottom: 14px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-card__auth__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-card__auth__desc {
  max-width: 340px;
  text-align: center;
  margin-bottom: 32px;
  font-size: 18px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.payment-card__auth__buttons {
  width: 100%;
  max-width: 460px;
  display: grid;
  grid-gap: 12px;
}
.payment-card__payment {
  padding: 20px 12px 12px 20px;
  background-color: #FAFBFD;
  border: solid 1px #EFF1F4;
  border-radius: 4px;
}
.payment-card__payment__amounts {
  display: flex;
  flex-wrap: wrap;
}
.payment-card__payment__amounts > * {
  margin-bottom: 6px;
}
.payment-card__payment__amounts__predefined {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
.payment-card__payment__amounts__predefined > * {
  margin-right: 6px;
  margin-bottom: 6px;
}
.payment-card__payment__amounts__input {
  flex-grow: 1;
}
.payment-card__payment__amounts__input .text-input__inner {
  background-color: #FFF;
}
.payment-card__payment__amounts__input .text-input__currency {
  color: #00A767;
}
.payment-card__payment__reward {
  display: flex;
  flex-direction: column;
}
.payment-card__payment__reward__details {
  height: 56px;
  background-color: #E5F5EF;
  border: solid 1px #00A767;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #00A767;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-card__payment__reward__remove {
  align-self: center;
  height: 40px;
  margin-top: 12px;
  padding: 0 12px;
  border-radius: 4px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #828288;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-card__payment__reward__remove:focus {
  outline: none;
  box-shadow: 0 0 0 6px rgba(0, 167, 103, 0.2);
  transition: box-shadow 350ms;
}
.payment-card__payment__types {
  display: flex;
  flex-wrap: wrap;
  margin-right: -32px;
  margin-bottom: -16px;
  margin-top: 12px;
}
.payment-card__payment__types > * {
  margin-right: 32px;
  margin-bottom: 16px;
}
.payment-card__selection {
  flex: 1 1 0;
  position: relative;
  cursor: pointer;
}
.payment-card__selection:hover .payment-card__selection__content {
  border-color: #00A767;
}
.payment-card__selection__checkbox {
  opacity: 0;
  z-index: -999;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}
.payment-card__selection__checkbox:checked ~ .payment-card__selection__content {
  border-color: #00A767;
  background-color: #E5F5EF;
}
.payment-card__selection__content {
  height: 56px;
  padding: 0 16px;
  background-color: #FFF;
  border: solid 1px #EFF1F4;
  border-radius: 4px;
  transition: background-color 350ms, border-color 350ms;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #00A767;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-card__user-details {
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 40px;
}
.payment-card__user-details__email {
  margin-bottom: 12px;
}
.payment-card__user-details__signature {
  margin-bottom: 12px;
}
.payment-card__user-details__signature__title {
  margin-bottom: 10px;
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.payment-card__user-details__anonymous {
  margin-bottom: 12px;
}
.payment-card__submit {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 440px;
}
.payment-card__submit__desc {
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.payment-card__card-data {
  margin-top: 18px;
  margin-bottom: 26px;
}
.payment-card__card-data__title {
  margin-bottom: 10px;
  color: #2E2F38;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
.payment-card__card-data__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 16px;
}
.payment-card__card-data__row:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .payment-summary .basic-modal__main {
    max-width: 640px;
  }
}
.payment-summary .basic-modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(130, 130, 136, 0.2);
  border-radius: 4px;
}
.payment-summary .basic-modal__close svg path {
  fill: #FFF;
}
.payment-summary__header {
  min-height: 132px;
  position: relative;
  background-image: url("/img/modal-header-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.payment-summary__content {
  padding: 40px 0;
}
.payment-summary__reward {
  padding: 0 24px;
  margin-bottom: 36px;
}
@media (min-width: 768px) {
  .payment-summary__reward {
    padding: 0 70px;
  }
}
.payment-summary__reward__title {
  margin-bottom: 8px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-summary__reward__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-summary__reward__card {
  border: solid 1px #EFF1F4;
  border-radius: 2px;
}
.payment-summary__platform-support {
  padding: 0 24px;
  margin-bottom: 36px;
}
@media (min-width: 768px) {
  .payment-summary__platform-support {
    padding: 0 70px;
  }
}
.payment-summary__platform-support__title {
  margin-bottom: 8px;
  color: #2E2F38;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-summary__platform-support__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-summary__platform-support__desc {
  margin-bottom: 16px;
  font-size: 14px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.payment-summary__support-amount {
  padding: 32px 24px;
  margin-bottom: 40px;
  background-color: #00A767;
}
@media (min-width: 768px) {
  .payment-summary__support-amount {
    padding: 32px 70px;
  }
}
.payment-summary__support-amount__title {
  margin-bottom: 8px;
  color: #FFF;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-summary__support-amount__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-summary__support-amount__dropdown .dropdown__placeholder {
  display: none;
}
.payment-summary__support-amount__dropdown .dropdown__arrow {
  top: 30px;
}
.payment-summary__support-amount__dropdown .choices__list--dropdown {
  top: calc(100% - 4px);
}
.payment-summary__support-amount__dropdown .choices__item {
  font-size: 16px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-summary__support-amount__dropdown .dropdown .choices__inner {
  padding-top: 10px;
  padding-bottom: 10px;
}
.payment-summary__support-amount__custom {
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
}
.payment-summary__support-amount__custom .text-input__native:not(:placeholder-shown) ~ .text-input__placeholder,
.payment-summary__support-amount__custom .text-input__native:focus ~ .text-input__placeholder {
  display: none;
}
.payment-summary__costs {
  padding: 12px 16px;
  margin: 0 24px 22px;
  background-color: #FAFBFD;
  border: solid 1px #EFF1F4;
  border-radius: 4px;
}
@media (min-width: 768px) {
  .payment-summary__costs {
    margin: 0 70px 22px;
  }
}
.payment-summary__costs__line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6px 0;
}
.payment-summary__costs__line:not(:last-child) {
  border-bottom: solid 1px #EFF1F4;
}
.payment-summary__costs__line__name {
  margin-right: 24px;
  font-size: 16px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}
.payment-summary__costs__line__value {
  white-space: nowrap;
  font-size: 16px;
  color: #2E2F38;
  font-weight: bold;
  line-height: 1.75em;
}
.payment-summary__costs__line__value--danger {
  color: #FF5A55;
}
.payment-summary__costs__total {
  margin-top: 12px;
  text-align: right;
  color: #00A767;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .payment-summary__costs__total {
    font-size: 24px;
    line-height: 32px;
  }
}
.payment-summary__agreements {
  display: grid;
  grid-gap: 12px;
  max-width: 460px;
  padding: 0 24px;
  margin: 0 auto 30px;
}
.payment-summary__submit {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  padding: 0 24px;
  margin: 0 auto;
}
.payment-summary__submit__desc {
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}

.socials-share {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}
@media (min-width: 768px) {
  .socials-share {
    grid-gap: 40px;
  }
}
.socials-share__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF;
  outline: none;
  border: none;
  cursor: pointer;
}
.socials-share__item:hover {
  text-decoration: underline;
  text-decoration-color: #3FA2F7;
}
.socials-share__item__icon {
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: solid 1px #00A767;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socials-share__item__icon--facebook {
  border-color: #3B5998;
}
.socials-share__item__icon--twitter {
  border-color: #1EA1F2;
}
.socials-share__item__label {
  font-family: "proxima-nova", sans-serif;
  font-size: 14px;
  color: #2E2F38;
  font-weight: normal;
  line-height: 1.75em;
}

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
}
.blog-card__thumbnail {
  position: relative;
  width: 100%;
  padding-top: 75%;
}
.blog-card__thumbnail__img {
  border-radius: 4px 4px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-card__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  padding: 20px 16px 30px;
}
.blog-card__content__title {
  font-size: 18px;
  font-weight: bold;
}
.blog-card__content__title__more {
  text-decoration: none;
}
.blog-card__content__title__more:hover {
  text-decoration: none;
}
.blog-card__content__desc {
  margin-top: 14px;
  color: #828288;
  line-height: 24px;
  word-break: break-word;
}
.blog-card__content__more {
  margin-right: auto;
  margin-top: auto;
}

.blog-grid {
  padding-top: 92px;
}
.blog-grid__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  grid-gap: 18px;
}
@media (min-width: 768px) {
  .blog-grid__items {
    grid-template-columns: repeat(auto-fill, minmax(min(415px, 100%), 1fr));
  }
}
.blog-grid__items__info {
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .blog-grid__items__info {
    font-size: 32px;
    line-height: 40px;
  }
}
.blog-grid__pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 154px;
  margin-top: 56px;
}

.blog-post-preview {
  padding-top: 120px;
  padding-bottom: 50px;
}
@media (min-width: 460px) {
  .blog-post-preview {
    padding-bottom: 100px;
  }
}
.blog-post-preview__items {
  background-color: #FFFFFF;
}
.blog-post-preview__items__thumbnail {
  position: relative;
  padding-bottom: 56.2%;
  margin-bottom: 40px;
  max-height: 700px;
}
.blog-post-preview__items__thumbnail::after {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 0 40px 0 0;
  background-color: #00A767;
  position: absolute;
  left: 24px;
  bottom: -40px;
}
@media (min-width: 460px) {
  .blog-post-preview__items__thumbnail::after {
    left: 60px;
  }
}
@media (min-width: 768px) {
  .blog-post-preview__items__thumbnail::after {
    left: 20%;
  }
}
.blog-post-preview__items__thumbnail__img {
  position: absolute;
  border-radius: 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-post-preview__items__content {
  padding: 33px 24px 42px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 460px) {
  .blog-post-preview__items__content {
    padding: 66px 60px 84px;
  }
}
@media (min-width: 768px) {
  .blog-post-preview__items__content {
    padding: 66px 20% 84px;
  }
}
.blog-post-preview__items__content__title {
  color: #2E2F38;
  font-size: 28px;
  line-height: 36px;
  font-weight: 800;
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .blog-post-preview__items__content__title {
    font-size: 38px;
    line-height: 52px;
  }
}
.blog-post-preview__items__content__article h3 {
  margin-bottom: 16px;
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .blog-post-preview__items__content__article h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.blog-post-preview__items__content__article p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.blog-post-preview__items__content__article strong {
  font-weight: bold;
}
.blog-post-preview__items__content__article ul,
.blog-post-preview__items__content__article ol {
  margin-bottom: 16px;
  padding-left: 18px;
}
.blog-post-preview__items__content__article ul li,
.blog-post-preview__items__content__article ol li {
  font-size: 16px;
  color: #828288;
  font-weight: normal;
  line-height: 1.75em;
}
.blog-post-preview__items__content__article ul li:not(:last-child),
.blog-post-preview__items__content__article ol li:not(:last-child) {
  margin-bottom: 16px;
}
.blog-post-preview__items__content__article ol {
  list-style-type: decimal;
}
.blog-post-preview__items__content__article ul {
  list-style-type: disc;
}
.blog-post-preview__items__content__article figure {
  margin-bottom: 16px;
}

.similar-article {
  background-color: #EFF1F4;
}

.similar-articles__items {
  padding: 33px 0px 50px;
}
@media (min-width: 460px) {
  .similar-articles__items {
    padding: 67px 42px 100px;
  }
}
.similar-articles__items__title {
  color: #2E2F38;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .similar-articles__items__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.similar-articles__items__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  grid-gap: 18px;
}