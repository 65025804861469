.campaign-aside {
  &--sticky {
    @media(min-width: $lg) and (min-height: 820px) {
      position: sticky;
      top: 136px;
    }
  }

  &__edit-button {
    margin-bottom: 8px;
    width: 100%;
  }

  &__promote-button {
    margin-bottom: 16px;
    width: 100%;
  }

  &__basic {
    padding: 16px;
    margin-bottom: 4px;
    background-color: $white;
    border-radius: 2px;

    &__badges {
      display: flex;
      margin-bottom: 12px;
      overflow: hidden;

      &__item {
        padding: 4px 16px;
        border-radius: 100px;
        @include crop-text;
        @include label($grey-900, true);

        &--category {
          background-color: $blue-100;
          color: $blue-500;
        }
      }
    }

    &__voivodeship {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @include text(14px, $grey-500);

      &__icon {
        flex-shrink: 0;
        margin-top: -2px;
        margin-right: 4px;
      }
    }

    &__amount {
      margin-bottom: 12px;

      &__left {
        @include heading-1($primary, true);
      }

      &__goal {
        @include text(18px);
      }
    }

    &__status {
      margin: 8px 0;
      @include label($grey-500, true);
    }

    &__actions {
      display: flex;
      margin-top: 8px;

      &__support,
      &__like {
        @media(max-width: $sm) {
          height: 56px;
        }
      }

      &__support {
        flex-grow: 1;
        margin-right: 12px;
      }

      &__like {
        flex-shrink: 0;
      }
    }
  }

  &__stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    padding: 16px;
    background-color: $white;
    border-radius: 2px;
    margin-bottom: 4px;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      background-color: $grey-50;

      &__icon {
        width: 32px;
        height: 32px;
        margin-bottom: 12px;

        @media(min-width: $sm) {
          width: 48px;
          height: 48px;
        }

        path {
          fill: $primary;
        }
      }

      &__value {
        margin-bottom: 4px;
        @include heading-3($primary);
      }

      &__name {
        @include text(14px, $grey-900, bold);
      }
    }
  }

  &__author {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 2px;
    padding: 16px 24px;
    margin-bottom: 4px;

    &__avatar {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      border-radius: 50%;
      object-fit: cover;

      @media(min-width: $sm) {
        width: 56px;
        height: 56px;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      overflow: hidden;

      &__label {
        @include label($grey-900, true);
      }

      &__name {
        word-break: break-all;
        white-space: nowrap;
        @include crop-text;
        @include text(14px, $blue-500);

        @media(min-width: $xs) {
          @include heading-3($blue-500, true);
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__badge {
      margin-right: 12px;
    }

    &__info {
      margin-left: auto;
      background: none;
      border: none;
      border-radius: 50%;
      padding: 0;
      cursor: pointer;
      @include flex-center;

      &:focus {
        @include focus-outline;
      }

      svg {
        path {
          fill: $primary;
        }
      }
    }
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-bottom: 12px;

    &__icon {
      margin-right: 8px;
    }

    &__text {
      @include text(16px, $blue-500, bold);
    }
  }

  &__rewards {
    display: grid;
    grid-gap: 4px;

    &__item {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 16px;
      border-radius: 2px;

      &__details {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 12px;
      }

      &__amount {
        margin-bottom: 8px;
        @include heading-2($primary);
      }

      &__supporters {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__icon {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }

        &__label {
          margin-right: 8px;
          @include text(14px);
        }

        &__count {
          @include text(14px, $blue-500, bold);
        }
      }

      &__description {
        word-break: break-word;
        @include text(14px, $grey-500);
      }

      &__select {
        margin-top: 8px;
      }

      &__date {
        display: flex;
        margin-bottom: 8px;

        &__label {
          margin-right: 4px;
          @include text(14px, $blue-500, bold);
        }

        &__value {
          @include text(14px, $grey-500);
        }
      }
    }
  }

  &__support {
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
  }
}
