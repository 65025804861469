.blog-post-preview {
  padding-top: 120px;
  padding-bottom: 50px;

  @media(min-width: $xs) {
    padding-bottom: 100px;
  }

  &__items {
    background-color: #FFFFFF;

    &__thumbnail {
      position: relative;
      padding-bottom: 56.2%;
      margin-bottom: 40px;
      max-height: 700px;

      &::after {
        content: "";
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 0 40px 0 0;
        background-color: $primary;
        position: absolute;
        left: 24px;
        bottom: -40px;

        @media(min-width: $xs) {
          left: 60px;
        }
        @media(min-width: $md) {
          left: 20%;
        }
      }

      &__img {
        position: absolute;
        border-radius: 2px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    &__content {
      padding: 33px 24px 42px;
      display: flex;
      flex-direction: column;

      @media(min-width: $xs) {
        padding: 66px 60px 84px;
      }
      @media(min-width: $md) {
        padding: 66px 20% 84px;
      }

      &__title {
        @include heading-1($grey-900);
        margin-bottom: 35px;
      }

      &__article {
        @include article-styles;
      }
    }
  }
}
