.payment-card {
  padding: 40px 24px;
  background-color: $white;
  border-radius: 2px;
  position: relative;

  @media(min-width: $md) {
    padding: 40px 60px;
  }

  &__scroll-anchor {
    position: absolute;
    top: -120px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 14px 24px;
    margin-bottom: 30px;

    &__title {
      @include heading-3();
    }

    &__donors {
      display: flex;
      align-items: center;

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &__label {
        margin-right: 8px;
        @include text(14px);
      }

      &__value {
        @include text(14px, $blue-500, bold);
      }
    }
  }

  &__content {
    padding: 36px 0 0;
  }

  &__auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 46px 32px 0;
    margin: 0 auto;

    &__img {
      margin-bottom: 40px;
      max-width: 240px;
    }

    &__title {
      max-width: 440px;
      text-align: center;
      margin-bottom: 14px;
      @include heading-3();
    }

    &__desc {
      max-width: 340px;
      text-align: center;
      margin-bottom: 32px;
      @include text(18px, $grey-500);
    }

    &__buttons {
      width: 100%;
      max-width: 460px;
      display: grid;
      grid-gap: 12px;
    }
  }

  &__payment {
    padding: 20px 12px 12px 20px;
    background-color: $grey-50;
    border: solid 1px $grey-100;
    border-radius: 4px;

    &__amounts {
      display: flex;
      flex-wrap: wrap;

      & > * {
        margin-bottom: 6px;
      }

      &__predefined {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;

        & > * {
          margin-right: 6px;
          margin-bottom: 6px;
        }
      }

      &__input {
        flex-grow: 1;

        .text-input {
          &__inner {
            background-color: $white;
          }

          &__currency {
            color: $primary;
          }
        }
      }
    }

    &__reward {
      display: flex;
      flex-direction: column;

      &__details {
        height: 56px;
        background-color: $green-100;
        border: solid 1px $primary;
        border-radius: 4px;
        @include flex-center;
        @include text(16px, $primary, bold);
      }

      &__remove {
        align-self: center;
        height: 40px;
        margin-top: 12px;
        padding: 0 12px;
        border-radius: 4px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        @include flex-center;
        @include text(14px, $grey-500, bold);

        &:focus {
          @include focus-outline;
        }
      }
    }

    &__types {
      display: flex;
      flex-wrap: wrap;
      margin-right: -32px;
      margin-bottom: -16px;
      margin-top: 12px;

      & > * {
        margin-right: 32px;
        margin-bottom: 16px;
      }
    }
  }

  &__selection {
    flex: 1 1 0;
    position: relative;
    cursor: pointer;

    &:hover {
      .payment-card__selection__content {
        border-color: $primary;
      }
    }

    &__checkbox {
      opacity: 0;
      z-index: -999;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;

      &:checked ~ .payment-card__selection__content {
        border-color: $primary;
        background-color: $green-100;
      }
    }

    &__content {
      height: 56px;
      padding: 0 16px;
      background-color: $white;
      border: solid 1px $grey-100;
      border-radius: 4px;
      transition: background-color 350ms, border-color 350ms;
      white-space: nowrap;
      @include flex-center;
      @include text(16px, $primary, bold);
    }
  }

  &__user-details {
    max-width: 440px;
    margin: 0 auto;
    padding-bottom: 40px;

    &__email {
      margin-bottom: 12px;
    }

    &__signature {
      margin-bottom: 12px;

      &__title {
        margin-bottom: 10px;
        @include label($grey-900, bold);
      }
    }

    &__anonymous {
      margin-bottom: 12px;
    }
  }

  &__submit {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 440px;

    &__desc {
      margin-top: 12px;
      text-align: center;
      @include text(16px, $grey-500);
    }
  }

  &__card-data {
    margin-top: 18px;
    margin-bottom: 26px;

    &__title {
      margin-bottom: 10px;
      @include label($grey-900, bold);
    }

    &__row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      grid-gap: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
