.footer {
  &__social {
    background-color: $primary;
    padding: 52px 0 64px;

    &__content {
      display: flex;
      flex-direction: column;

      @media(min-width: $lg) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__main {
    background-color: $grey-900;
    padding: 64px 0 40px;

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__copyright {
    background-color: $grey-900;
    border-top: solid 1px rgba($white, 0.1);
    padding: 32px 0;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      p {
        @include text(16px, $grey-100);
      }
    }
  }

  &__investor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px;
    background-color: $white;

    @media(min-width: $md) {
      flex-direction: row;
      justify-content: center;
    }

    &__text {
      max-width: 420px;
      margin: 32px 0;
      text-align: center;
      @include text(14px, $grey-500, bold);

      @media(min-width: $md) {
        margin: 0 46px;
      }
    }

    &__container{
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 200px;
      height: 200px;
    }

    img {
      max-width: 100%;
    }
  }

  &__group {
    margin-right: 32px;
    margin-bottom: 32px;
    flex: 1 0 220px;
    overflow: hidden;

    &__title {
      @include heading-3($white);
      margin-bottom: 16px;
    }

    &__list {
      &--bullets {
        li {
          position: relative;
          padding-left: 20px;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            &::before {
              transform: scale(1.2);
            }
          }

          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary;
            position: absolute;
            left: 0;
            top: 0.7em;
            transition: transform 350ms;
          }
        }
      }

      &__item {
        @include text(16px, $grey-100);

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        a {
          color: inherit;
        }
      }
    }
  }

  &__socials {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    @media(min-width: $lg) {
      margin-right: 64px;
      margin-bottom: 0;
    }

    &__brand {
      margin-bottom: 24px;

      img {
        max-width: 100%;
      }
    }

    &__list {
      display: grid;
      grid-gap: 34px;
      grid-template-columns: repeat(auto-fit, 24px);

      &__item {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__newsletter {
    max-width: 524px;
    flex: 1 1 0;
  }

  &__scroll_top {
    @include flex-center;
    position: relative;
    align-self: flex-end;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    border-radius: 50%;

    @media(min-width: $sm) {
      margin-left: auto;
    }

    &:focus {
      @include focus-outline;
    }

    &:hover {
      .footer__scroll_top__circle {
        animation: mouseoverScrollTop 800ms cubic-bezier(0.3, 0.1, 0, 1) forwards;
      }
    }

    &__circle {
      stroke-dasharray: 402;
      stroke-dashoffset: 402;
      animation: mouseoutScrollTop 1.2s cubic-bezier(0.6, 0.5, 0, 1) forwards;
      pointer-events: none;
    }

    &__arrow {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
}

@keyframes mouseoverScrollTop {
  0% {
    stroke-dasharray: 0;
    transform: rotate(0);
    stroke-width: 2px
  }

  15% {
    stroke-dasharray: 1, 3;
    stroke-width: 1px
  }

  100% {
    stroke-dasharray: 1, 3;
    transform: rotate(35deg);
    stroke-width: 1px
  }
}

@keyframes mouseoutScrollTop {
  from {
    transform: rotate(-45deg);
    stroke-dashoffset: 406
  }

  to {
    transform: rotate(85deg);
    stroke-dashoffset: 0
  }
}
