.about-page {
  &__hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: $primary;
    padding: 78px + $navbar-height 32px 0;

    &__claimer {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 848px;
      margin-bottom: 48px;

      @media(min-width: $sm) {
        margin-bottom: 82px;
      }

      @media(min-width: $xl) {
        margin-bottom: 18px;
      }

      &__brand {
        width: 100%;
        max-width: 414px;
        margin-bottom: 36px;
      }

      &__title {
        @include heading-2($white, true);
        text-align: center;
      }
    }

    &__main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @media(min-width: $xl) {
        flex-wrap: nowrap;
      }

      &__tree {
        margin: auto 0 0;
        position: relative;
        z-index: 0;
        overflow: hidden;
        order: 1;

        @media(min-width: $sm) {
          margin: auto 82px 0;
        }

        @media(min-width: $xl) {
          order: 0;
          margin: auto -72px 0;
        }

        &::after {
          content: "";
          display: block;
          width: 95%;
          padding-top: 95%;
          position: absolute;
          left: 50%;
          top: 22px;
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: $green-600;
          z-index: -1;
        }

        img {
          display: block;
          max-width: 100%;
        }
      }

      &__brand {
        margin: 32px 42px;
        max-width: 100%;

        @media(min-width: $sm) {
          margin: 0 42px 82px;
        }

        @media(min-width: $xl) {
          margin: 0 0 14vw;
        }
      }
    }
  }

  &__jumbotron {
    @media(min-width: $xl) {
      margin-right: 84px;
    }
  }

  &__support {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 120px 0 40px;
    padding-bottom: 42px;
    background-color: $white;

    @media(min-width: $xl) {
      margin: 240px 0 184px;
    }

    @media(min-width: $xl) {
      margin-right: 84px;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;
    }

    &__payment {
      align-self: center;
      max-width: 660px;

      @media(min-width: $xl) {
        margin-right: 32px;
      }

      &__title,
      &__desc {
        padding: 0 24px;

        @media(min-width: $md) {
          padding: 0 60px;
        }

        @media(min-width: $xl) {
          margin-left: 60px;
          padding: 0;
        }
      }

      &__title {
        margin-top: 92px;
        margin-bottom: 8px;
        padding: 0 24px;
        @include heading-1();

        @media(min-width: $md) {
          padding: 0 60px;
        }

        @media(min-width: $xl) {
          padding: 0;
        }

        span {
          color: $primary;
        }
      }

      &__subtitle {
        margin-top: 24px;
        margin-bottom: 12px;
        padding: 0 24px;
        @include heading-3();

        @media(min-width: $md) {
          padding: 0 60px;
        }

        @media(min-width: $xl) {
          margin-left: 60px;
          padding: 0;
        }
      }

      &__desc {
        @include text(18px, $grey-500, bold);
      }

      &__card {
        margin-top: 32px;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;

      @media(min-width: $xl) {
        margin-top: -64px;
        margin-right: 84px;
        margin-bottom: -64px;
      }

      img {
        max-width: 100%;
      }
    }
  }

  &__section-title-separator {
    max-width: 1240px;
    @include heading-2();

    @media(min-width: $md) {
      padding: 42px 0;
    }

    &--small {
      max-width: 1080px;
    }

    @media(min-width: $md) {
      @include heading-1();
    }

    span {
      color: $primary;
    }
  }
}
