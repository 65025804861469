.how-it-works-page {
  &__content {
    margin-top: 64px;

    &__leading {
      &__bar {
        height: 68px;
        background-color: $white;
        margin-left: 120px;
        @include display-from($xl);
      }
    }

    &__main {
      background-color: $white;
      padding: 100px 0 90px;

      @media(min-width: $xl) {
        margin-left: 120px;
      }
    }
  }

  &__lead {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;

    @media(min-width: $xl) {
      flex-direction: row;
    }

    &__image {
      background-color: $white;
      margin-top: 64px;
      padding: 0 32px 64px;

      @media(min-width: $xl) {
        margin: 0;
        padding: 0 32px 0 0;
        width: 50%;
      }

      img {
        display: block;
        max-width: 100%;
        margin-top: -64px;

        @media(min-width: $xl) {
          margin-top: 0;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 36px;
      background-color: $white;

      @media(min-width: $xl) {
        padding: 0 72px;
        width: 50%;
      }

      &__title,
      &__desc,
      &__text {
        max-width: 540px;
      }

      &__title {
        margin-bottom: 50px;
        @include heading-1($primary);
      }

      &__desc {
        margin-bottom: 28px;
        @include heading-3();
      }

      &__text {
        @include text(18px);
      }
    }
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media(min-width: $md) {
      flex-direction: row;
    }

    @media(min-width: $xl) {
      padding-right: 5vw;
    }

    &:not(:last-child) {
      margin-bottom: 100px;
    }

    &:nth-child(even) {
      @media(min-width: $md) {
        flex-direction: row-reverse;
      }
    }

    &:nth-child(odd) {
      .how-it-works-page__step__image {
        text-align: right;
      }
    }

    &__article {
      flex: 1 1 0;
      padding: 0 32px;
      margin-bottom: 42px;
      position: relative;
      z-index: 0;

      @media(min-width: $lg) {
        padding: 0 72px;
        margin-bottom: 0;
      }

      &__title {
        margin-bottom: 8px;
        max-width: 576px;
        @include heading-3($primary);
      }

      &__text {
        max-width: 576px;
        @include text(18px);
      }

      &__number {
        position: absolute;
        font-weight: bold;
        font-size: 144px;
        line-height: 112px;
        color: $green-100;
        font-family: $default-font;
        top: -32px;
        left: 24px;
        z-index: -1;
        pointer-events: none;
      }
    }

    &__image {
      flex: 1 1 0;
      padding: 0 32px;

      @media(min-width: $lg) {
        padding: 0 72px;
      }

      img {
        max-width: 100%;
      }
    }
  }

  &__read-more {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__inner {
      display: flex;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      @include heading-2($black);

      @media(min-width: $md) {
        flex-direction: row;
      }

      span {
        color: $primary;

        @media(min-width: $md) {
          margin-left: 16px;
        }
      }
    }
  }
}
