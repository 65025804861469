.d-none {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.wrap-text {
  word-break: break-word;
}

.text-nowrap {
  white-space: nowrap;
}

.overflow {
  &-auto {
    overflow: auto;
  }

  &-hidden {
    overflow: hidden;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.cursor-pointer	{
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-success {
  color: $green-500;
}

.text-danger {
  color: $red-500;
}

.text-warning {
  color: $yellow-500;
}

.text-info {
  color: $blue-500;
}
