.contact-details {
  &__title {
    margin-bottom: 64px;
    @include heading-4();
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 42px;
    }
  }

  &__subgroup {
    &:not(:last-child) {
      margin-bottom: 28px;
    }

    &__title {
      margin-bottom: 10px;
      @include heading-4();
    }
  }

  &__socials {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: repeat(auto-fill, minmax(24px, 1fr));
  }

  &__company-adress {
    @include text(14px, $grey-500, bold);
  }

  &__company-info {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    @include text(14px, $grey-500, bold);

    li {
      a {
        color: inherit;
      }
    }
  }

  &__contact-btn {
    display: flex;
    align-items: center;
    @include text(16px, $grey-500);

    &__icon {
      margin-right: 12px;
    }
  }
}
