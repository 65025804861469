.profile-details-modal {
  .basic-modal {
    &__main {
      @media(min-width: $md) {
        max-width: 640px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: rgba($grey-500, .2);
      border-radius: 4px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &__header {
    min-height: 132px;
    margin-bottom: 40px + 10px;
    position: relative;
    background-image: url("/img/modal-header-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;

    &__avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      left: 30px;
    }
  }

  &__content {
    padding: 0 30px 20px;
  }

  &__profile-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    @media(min-width: $md) {
      flex-direction: row;
    }
  }

  &__group {
    &__label {
      margin-bottom: 4px;
      @include label($grey-900, bold);
    }
  }

  &__name {
    display: flex;
    margin-bottom: 24px;

    @media(min-width: $md) {
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__value {
      margin-right: 12px;
      word-break: break-word;
      @include heading-3($blue-500, true);
    }

    &__badge {
      pointer-events: none;

      path {
        fill: $blue-500;
      }
    }
  }

  &__socials {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    min-width: 200px;

    &__empty {
      @include label();

      &:not(:only-child) {
        display: none;
      }
    }
  }

  &__stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    margin-bottom: 24px;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      background-color: $grey-50;

      &__icon {
        width: 32px;
        height: 32px;
        margin-bottom: 12px;

        @media(min-width: $sm) {
          width: 48px;
          height: 48px;
        }

        path {
          fill: $primary;
        }
      }

      &__value {
        margin-bottom: 4px;
        @include heading-3($primary);
      }

      &__name {
        @include text(14px, $grey-900, bold);
      }
    }
  }

  &__about {
    margin-bottom: 24px;

    &__title {
      margin-bottom: 4px;
      @include text(16px, $grey-900, bold);
    }

    &__desc {
      word-break: break-word;
      @include text(16px, $grey-500);
    }
  }

  &__campaigns {
    display: grid;
    margin-bottom: 22px;

    &__title {
      margin-bottom: 6px;
      @include text(16px, $grey-900, bold);
    }

    &__list {
      display: grid;
      grid-gap: 8px;

      a {
        word-break: break-word;
        @include text(16px, $blue-500);
      }
    }
  }

  &__back {
    display: flex;
    justify-content: center;
    background-color: $white;
    position: sticky;
    bottom: 0;
    width: 100%;
  }
}
