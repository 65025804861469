.campaigns-swiper {
  &__title {
    margin: 0 0 46px;
    @include heading-1();

    @media(min-width: $lg) {
      margin: 0 110px 46px;
    }
  }

  &__swiper {
    display: flex;
    align-items: center;

    .swiper-slide {
      height: auto;

      .campaign-card {
        height: 100%;
      }
    }
  }

  &__prev,
  &__next {
    @include flex-center;
    @include display-from($sm);
    width: 80px;
    height: 80px;
    background: none;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: $primary-dark;
        }
      }
    }

    svg {
      path {
        transition: fill 350ms;
        fill: $primary;
      }
    }
  }

  &__prev {
    margin-right: 16px;

    @media(min-width: $md) {
      margin-right: 30px;
    }
  }

  &__next {
    margin-left: 16px;

    @media(min-width: $md) {
      margin-left: 30px;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media(min-width: $xs) {
      margin-bottom: 0;
    }

    .swiper-pagination-bullet {
      opacity: 1;
      background-color: $grey-200;

      &:not(:last-child) {
        margin-right: 16px;
      }

      &-active {
        width: 16px;
        height: 16px;
        background-color: $primary;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 44px 0 0;

    @media(min-width: $xs) {
      flex-direction: row;
      justify-content: space-around;
    }

    @media(min-width: $lg) {
      margin: 44px 110px 0;
      justify-content: space-between;
    }
  }
}
