.support-page {
  .about-page__support {
    margin: 0;

    @media(min-width: $xl) {
      align-items: flex-start;
    }

    &__image {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    &__payment {
      @media(min-width: $xl) {
        align-self: flex-start;
      }

      &__title {
        margin-top: 0;
      }
    }
  }
}
