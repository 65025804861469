.details-tabs {
  &__tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    padding: 1px;

    @media(min-width: $lg) {
      grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    }
  }

  &__tab {
    @include flex-center;
    flex-direction: column;
    padding: 16px;
    margin: -1px;
    cursor: pointer;
    outline: none;
    border: solid 1px $grey-100;
    background-color: $white;
    color: $grey-900;
    transition: color 350ms, background-color 350ms;

    @media(min-width: $sm) {
      padding: 42px;
    }

    &--active {
      background-color: $primary;
      color: $white;
    }

    &:not(.details-tabs__tab--active) {
      &:focus,
      &:hover {
        background-color: rgba($primary, 0.05);
      }
    }

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }

    &__image {
      display: block;
      margin-bottom: 6px;
      width: 42px;
      height: 42px;
      object-fit: contain;

      @media(min-width: $sm) {
        height: 64px;
        width: 64px;
      }
    }

    &__label {
      @include text(12px, $grey-900, bold);
      color: currentColor;
      text-transform: uppercase;

      @media(min-width: $sm) {
        font-size: 14px;
      }
    }
  }

  &__content {
    margin-top: 42px;
    max-width: 760px;
    @include text(18px, $grey-900);

    @media(min-width: $sm) {
      font-size: 24px;
    }
  }
}
