.campaigns-page {
  &__content {
    display: flex;
    flex-direction: column;

    @media(min-width: $lg) {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__filters {
    margin-bottom: 36px;
    background-color: $white;
    border-radius: 4px;
    overflow: auto;
    max-height: calc(96vh - #{$navbar-height * 1.5});

    @media(min-width: $lg) {
      flex: 1 0 310px;
      position: sticky;
      top: $navbar-height * 1.5;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &__campaigns {
    width: 100%;

    &__loader {
      padding: 32px 0;
      @include flex-center;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      grid-gap: 16px;
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__image {
        margin-bottom: 48px;
      }

      &__text,
      &__advice {
        @include heading-2($grey-500)
      }

      &__advice {
        color: $primary;
      }
    }

    &__load-btn {
      max-width: 310px;
      width: 100%;
      margin: 64px auto 0;
    }
  }
}
