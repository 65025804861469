$white: #FFF;
$black: #000;

$grey-50: #FAFBFD;
$grey-100: #EFF1F4;
$grey-200: #CFD1D7;
$grey-500: #828288;
$grey-900: #2E2F38;

$green-100: #E5F5EF;
$green-200: #CFEEE1;
$green-500: #00A767;
$green-600: #00975D;
$green-700: #0C8068;
$green-900: #045555;

$red-100: #FFE0DF;
$red-500: #FF5A55;

$blue-100: #EDF7FF;
$blue-500: #3FA2F7;

$yellow-100: #FFFBF1;
$yellow-500: #FBC121;

$primary: $green-500;
$primary-dark: $green-700;
$primary-darker: $green-900;

$transparent: rgba(#000, 0);

$navbar-height: 80px;

$default-font: "proxima-nova", sans-serif;

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 460px;
