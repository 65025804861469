.faq-card {
  border: solid 1px $grey-100;
  border-radius: 4px;
  background-color: $white;

  &--active {
    .faq-card {
      &__header {
        &__arrow {
          transform: rotate(180deg);
        }
      }

      &__content {
        display: block;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 12px;
    cursor: pointer;

    &__title {
      word-break: break-word;
      @include heading-4();
    }

    &__arrow {
      flex-shrink: 0;
      margin-left: 12px;
      transition: transform 150ms;
    }
  }

  &__content {
    padding: 0 12px 32px;
    margin-top: -12px;
    display: none;

    &__text {
      word-break: break-word;
      @include text(14px, $grey-500);
    }
  }
}
