.socials-share {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;

  @media(min-width: $md) {
    grid-gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $blue-500;
    }

    &__icon {
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
      border-radius: 8px;
      border: solid 1px $primary;
      @include flex-center;

      &--facebook {
        border-color: #3B5998;
      }

      &--twitter {
        border-color: #1EA1F2;
      }
    }

    &__label {
      font-family: $default-font;
      @include text(14px);
    }
  }
}

