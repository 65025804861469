@mixin text($fontSize: 16px, $color: $grey-900, $fontWeight: normal) {
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  line-height: 1.75em;
}

@mixin heading-1plus($color: $grey-900, $isLight: false) {
  color: $color;
  font-size: 32px;
  line-height: 46px;

  @if $isLight {
    font-weight: normal;
  } @else {
    font-weight: bold;
  }

  @media(min-width: $sm) {
    font-size: 64px;
    line-height: 72px;
  }
}

@mixin heading-1($color: $grey-900, $isLight: false) {
  color: $color;
  font-size: 28px;
  line-height: 36px;

  @if $isLight {
    font-weight: normal;
  } @else {
    font-weight: 800;
  }

  @media(min-width: $sm) {
    font-size: 38px;
    line-height: 52px;
  }
}

@mixin heading-2($color: $grey-900, $isLight: false) {
  color: $color;
  font-size: 22px;
  line-height: 30px;

  @if $isLight {
    font-weight: normal;
  } @else {
    font-weight: bold;
  }

  @media(min-width: $sm) {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin heading-3($color: $grey-900, $isLight: false) {
  color: $color;
  font-size: 18px;
  line-height: 26px;

  @if $isLight {
    font-weight: normal;
  } @else {
    font-weight: bold;
  }

  @media(min-width: $sm) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin heading-4($color: $grey-900, $isLight: false) {
  color: $color;
  font-size: 16px;
  line-height: 24px;

  @if $isLight {
    font-weight: normal;
  } @else {
    font-weight: bold;
  }
}

@mixin label($color: $grey-900, $isBold: false) {
  color: $color;
  font-size: 12px;
  line-height: 16px;

  @if $isBold {
    font-weight: bold;
  } @else {
    font-weight: normal;
  }
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}
