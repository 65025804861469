.jumbotron {
  &__header {
    padding: 64px 32px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: $white;

    @media(min-width: $sm) {
      padding: 90px 32px;
    }

    @media(min-width: $lg) {
      padding: 90px 64px;
    }
    
    @media(min-width: $xl) {
      padding: 90px 112px;
    }

    &__title {
      @include heading-1plus();
    }

    &__subtitle {
      margin-top: 18px;
      @include heading-2($grey-900, true);
    }

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      object-fit: cover;
      opacity: .5;

      @media(min-width: $lg) {
        left: 210px;
        opacity: 1;
      }
    }
  }

  &__content {
    background-color: $white;
    padding: 88px 32px;

    @media(min-width: $lg) {
      padding: 88px 64px;
    }

    @media(min-width: $xl) {
      padding: 88px 224px 112px 112px;
    }
  }
}
