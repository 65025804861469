.contact-page {
  &__header {
    background-position: right 0 bottom 80px;
    background-size: auto calc(100% - 32px - 168px);
  }

  &__content {
    background-color: $white;

    &__main {
      display: flex;
      flex-direction: column-reverse;
      padding: 64px 32px;
      margin-top: -84px;

      @media(min-width: $lg) {
        padding: 90px 32px;
      }

      @media(min-width: $xl) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 90px 32px 152px;
      }
    }
  }

  &__form,
  &__details {
    flex: 1 0 0;

    @media(min-width: $lg) {
      padding: 0 55px;
    }
  }

  &__form {
  }

  &__details {
    margin-bottom: 42px;

    @media(min-width: $xl) {
      margin-bottom: 0;
    }

    &__inner {
      max-width: 420px;
    }
  }

  &__map {
    iframe {
      display: block;
      width: 100%;
      height: 512px;
    }
  }
}
