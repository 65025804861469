.progress-circle {
  @include flex-center;
  width: 104px;
  height: 104px;
  position: relative;

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__text {
      @include text(14px, $primary-dark, bold);
    }

    &__value {
      @include heading-4($primary);
    }
  }

  &__circle {
    &__track {
      stroke: $grey-100;
    }

    &__progress {
      transform-origin: 50% 50%;
      transform: rotate(90deg);
    }
  }
}
