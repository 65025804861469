.contact-form {
  &__row {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;

    @media(min-width: $sm) {
      flex-direction: row;
    }

    .form-control {
      flex: 1 1 0;

      &:not(:last-child) {
        margin-bottom: 16px;

        @media(min-width: $sm) {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }

  &__message {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__agreement {
    display: flex;
    align-items: center;
    max-width: 320px;
    margin-bottom: 40px;
  }

  &__submit {
    width: 100%;
  }
}
