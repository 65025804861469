.wrapper {
  padding: 0 24px;
  display: flex;
  justify-content: center;

  @media(min-width: $xl) {
    padding: 0 96px;
  }

  &--flat {
    padding: 0;
  }

  &--content-right {
    justify-content: flex-end;
  }

  &--content-left {
    justify-content: flex-start;
  }
}

.container {
  width: 100%;
  max-width: 1518px;

  &--small {
    max-width: 1322px;
  }

  &--large {
    max-width: 1710px;
  }

  &--fluid {
    max-width: 100%;
  }
}

.scroll-header {
  &--active {
    .scroll-header {
      &__header {
        padding-top: 0;
        padding-bottom: 0;
        background: $primary;
      }

      &__content {
        padding-top: 460px;
      }
    }
  }

  &__content {

  }
}

.header {
  padding-top: 110px + $navbar-height;
  padding-bottom: 136px;
  background-color: $primary;
  transition-property: padding;
  transition-duration: 350ms;

  @media(min-width: $md) {
    background-image: url("/img/header-pattern-bg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto calc(100% - 32px - #{$navbar-height});
  }

  &--scroll {
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 10;
    top: $navbar-height;
    left: 0;
    width: 100%;
    height: 120px;
    background: $primary;

    .header {
      &__title {
        @include heading-1($white);
      }

      &__desc {
        margin-top: 0;
        font-size: 0;
        line-height: 0;
      }
    }
  }

  &--has-tabs {
    padding-bottom: 0;
  }

  &__title {
    @include heading-1plus($white);
    transition: font-size 350ms;
  }

  &__desc {
    @include heading-2($white, true);
    margin-top: 18px;
    transition: font-size 350ms, margin-top 350ms;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;

    @media(min-width: $sm) {
      flex-direction: row;
    }

    &__item {
      position: relative;
      padding: 24px 0;
      transition: color 350ms;
      @include heading-4($green-200);

      @media(min-width: $sm) {
        padding: 40px 0;
      }

      @media(min-width: $md) {
        @include heading-3($green-200);
      }

      &:hover {
        text-decoration: none;
        color: $white;
      }

      &--active {
        color: $white;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $white;
          position: absolute;
          bottom: 0;
          left: 0;

          @media(min-width: $lg) {
            width: calc(100% + 32px);
            height: 4px;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 52px;

        @media(min-width: $lg) {
          margin-right: 100px;
        }
      }
    }
  }
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;

  &--small {
    @media(min-width: $md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &:not(.section--small) {
    @media(min-width: $md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &:first-child {
      padding-top: 60px;

      @media(min-width: $md) {
        padding-top: 120px;
      }
    }

    &:last-child {
      padding-bottom: 60px;

      @media(min-width: $md) {
        padding-bottom: 120px;
      }
    }
  }
}
