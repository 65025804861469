.campaign-details {
  &__content {
    padding: 40px 24px;
    margin-bottom: 32px;
    background-color: $white;

    @media(min-width: $md) {
      padding: 40px 60px;
    }

    &__title {
      margin-bottom: 16px;
      @include heading-2();
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 75%;

    &::after {
      content: "";
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 0 40px 0 0;
      background-color: $primary;
      position: absolute;
      left: 24px;
      bottom: -40px;

      @media(min-width: $md) {
        left: 60px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2px;
    }
  }

  &__title {
    padding: 66px 24px 24px;
    background-color: $white;
    word-break: break-word;
    @include heading-1();

    @media(min-width: $md) {
      padding: 66px 60px 24px;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px $grey-100;
    padding: 24px 24px 0;
    background-color: $grey-50;
    position: relative;

    @media(min-width: $md) {
      padding: 24px 60px 0;
    }

    @media(min-width: $lg) {
      flex-direction: row;
      align-items: flex-end;
    }

    &__scroll-target {
      position: absolute;
      top: -$navbar-height * 2;
    }

    &__item {
      padding: 12px;
      transition: color 350ms;
      position: relative;
      @include text(14px, $grey-900, bold);

      @media(min-width: $lg) {
        padding: 12px 12px 24px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      &.current {
        color: $primary;

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 3px;
          height: 100%;
          background-color: $primary;

          @media(min-width: $lg) {
            width: 100%;
            height: 3px;
          }
        }
      }

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  &__description {
    background-color: $white;
    word-break: break-word;
    @include article-styles;

    .image {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 16px;

      @media(min-width: $md) {
        margin-left: -60px;
        margin-right: -60px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__updates {
    display: grid;
    grid-gap: 20px;

    &__item {
      display: flex;
      align-items: flex-start;
      padding: 20px;
      border: solid 1px $grey-100;
      border-radius: 4px;

      &__avatar {
        margin-right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        object-fit: cover;

        @media(min-width: $sm) {
          width: 42px;
          height: 42px;
        }
      }

      &__content {
        &__details {
          display: flex;
          align-items: center;

          &__author {
            margin-right: 8px;
            @include text(14px, $grey-900, bold);
          }

          &__date {
            @include label($grey-500);
          }
        }

        &__title {
          word-break: break-word;
          @include heading-4();
        }

        &__desc {
          @include article-styles;

          h3 {
            word-break: break-word;
            @include heading-4();
          }
        }
      }
    }

    &__empty {
      @include text(16px);
    }
  }

  &__faqs {
    display: grid;
    grid-gap: 10px;

    &__empty {
      @include text(16px);
    }
  }

  &__goals-timeline {
    margin-bottom: 30px;
    padding: 46px 24px;
    background-color: $grey-50;
    border-radius: 8px;
  }

  &__goals {
    display: grid;
    grid-gap: 40px;

    &__item {
      &__title {
        margin-bottom: 12px;
        word-break: break-word;
        @include heading-4();
      }

      &__amount {
        margin-bottom: 12px;
        @include heading-3($primary);
      }

      &__desc {
        word-break: break-word;
        @include text(16px, $grey-500);
      }
    }

    &__empty {
      @include text(16px);
    }
  }

  &__payments {
    margin-bottom: 20px;
    background-color: $white;
    border-top: solid 1px $grey-100;

    &__header {
      padding: 36px 24px 0;
      border-bottom: solid 1px $grey-100;

      @media(min-width: $md) {
        padding: 36px 60px 0;
      }

      &__title {
        margin-bottom: 12px;
        @include heading-2();
      }

      &__tabs {
        display: flex;

        &__item {
          padding: 12px 14px 12px 0;
          background: none;
          border: none;
          outline: none;
          cursor: pointer;
          position: relative;
          transition: color 350ms;
          text-align: left;
          @include text(12px, $grey-500, bold);

          @media(min-width: $sm) {
            padding: 14px 42px 14px 0;
            @include text(14px, $grey-500, bold);
          }

          &:not(:last-child) {
            margin-right: 32px;
          }

          &:hover {
            color: $primary;
          }

          &--active {
            color: $primary;

            &::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: $primary;
            }
          }
        }
      }
    }

    &__content {
      padding: 20px 24px 54px;
      overflow: hidden;

      @media(min-width: $md) {
        padding: 20px 60px 54px;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px 20px;

      @media(min-width: $md) {
        grid-template-columns: repeat(2, 1fr);
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: $white;
        border: solid 1px $grey-100;
        overflow: hidden;

        &__avatar {
          flex-shrink: 0;
          margin-right: 18px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          object-fit: cover;

          @media(min-width: $sm) {
            width: 42px;
            height: 42px;
          }
        }

        &__details {
          margin-right: 12px;
          overflow: hidden;
        }

        &__name {
          @include crop-text;
          @include text(14px, $grey-900, bold);

          @media(min-width: $sm) {
            @include heading-4();
          }
        }

        &__date {
          @include text(12px, $grey-500);

          @media(min-width: $sm) {
            @include text(14px, $grey-500);
          }
        }

        &__amount {
          margin-left: auto;
          white-space: nowrap;
          @include heading-4($primary);
        }
      }

      &__empty {
        @include text(16px, $grey-500);

        &:not(:only-child) {
          display: none;
        }
      }
    }

    &__load-more {
      margin-top: 24px;
    }
  }

  &__comments {
    background-color: $white;

    &__header {
      padding: 36px 24px 20px;
      border-bottom: solid 1px $grey-100;

      @media(min-width: $md) {
        padding: 36px 60px 20px;
      }

      &__title {
        @include heading-2();
      }
    }

    &__content {
      padding: 20px 24px 54px;
      overflow: hidden;

      @media(min-width: $md) {
        padding: 20px 60px 54px;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;

      &__item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 4px;
        border: solid 1px $grey-100;
        overflow: hidden;

        &__details {
          display: flex;
          align-items: flex-start;
        }

        &__avatar {
          flex-shrink: 0;
          margin-right: 12px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          object-fit: cover;

          @media(min-width: $sm) {
            width: 42px;
            height: 42px;
          }
        }

        &__datename {
          display: flex;
          flex-direction: column;

          @media(min-width: $sm) {
            flex-direction: row;
            align-items: center;
          }
        }

        &__name {
          margin-right: 8px;
          MAX-WIDTH: 220px;
          @include text(14px, $grey-900, bold);
          @include crop-text;
          line-height: 1.25em;

          @media(min-width: $sm) {
            line-height: 1.75em;
          }
        }

        &__date {
          @include label($grey-500);

          @media(min-width: $sm) {
            line-height: 1.75rem;
          }
        }

        &__aside {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-left: auto;
        }

        &__amount {
          white-space: nowrap;
          @include heading-4($primary);
          line-height: 1.75rem;
        }

        &__delete {
          margin-top: 8px;
        }

        &__description {
          margin-top: 12px;
          word-break: break-word;
          @include text(14px);

          @media(min-width: $sm) {
            margin-top: -16px;
            margin-right: 82px;
            margin-left: 42px + 12px;
          }
        }
      }

      &__empty {
        @include text(16px, $grey-500);

        &:not(:only-child) {
          display: none;
        }
      }
    }

    &__load-more {
      margin-top: 24px;
    }
  }

  &__payment-card {
    margin-top: 20px;
  }

  &__pending {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 24px 56px;
    margin-bottom: 48px;
    border-radius: 2px;
    background-color: $white;

    @media(min-width: $md) {
      padding: 46px 90px 96px 80px;
    }

    &__image {
      margin-bottom: 56px;
      max-width: 100%;
    }

    &__title {
      margin-bottom: 34px;
      @include heading-2();
    }
  }
}
