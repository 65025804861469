.hero-swiper {
  width: 100%;

  &__slide {
    display: flex;
    padding: 136px 32px 74px;
    min-height: 100vh;
    position: relative;
    z-index: 0;

    @media(min-width: $lg) {
      padding: 246px 32px 74px;
    }
  }

  &__bg,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__bg {
    object-fit: cover;
    z-index: -2;
  }

  &__overlay {
    background: rgba($black, 0.45);
    z-index: -1;
  }

  &__badge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 64px;
    text-transform: uppercase;
    overflow: hidden;
    @include text(14px, $white, bold);

    @media(min-width: $lg) {
      margin-bottom: 100px;
    }

    &__name {
      margin-right: 20px;
      @include crop-text;
    }

    &__category {
      color: $white;
      @include crop-text;
    }
  }

  &__content {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &__main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 48px;

      @media(min-width: $lg) {
        flex-direction: row;
      }
    }
  }

  &__claim {
    &__title {
      margin-bottom: 24px;
      max-width: 890px;
      @include crop-text;
      @include heading-1plus($white);
    }

    &__desc {
      @include heading-4($white);
      @include crop-text;
      max-width: 524px;
    }
  }

  &__controls {
    display: flex;
    margin-top: 32px;

    @media(min-width: $lg) {
      margin-top: 0;
    }

    &__back {
      margin-right: 10px;
    }

    &__back,
    &__next {
      @include flex-center;
      width: 40px;
      height: 40px;
      background: none;
      cursor: pointer;
      outline: none;
      border: none;
      opacity: .75;
      transition: opacity 350ms;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__like {
    background-color: lighten($red-500, 50);
    //&:hover {
    //  background-color: lighten($red-500, .5);
    //}
  }

  &__bar {
    padding: 24px;
    background-color: $white;
    border-radius: 4px;
    margin-top: auto;

    @media(min-width: $lg) {
      padding: 18px 64px;
    }

    &__inner {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media(min-width: $xl) {
        flex-direction: row;
      }
    }

    &__progress {
      margin-bottom: 32px;

      @media(min-width: $xl) {
        margin-bottom: 0;
      }
    }

    &__stats {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 0;

      @media(min-width: $sm) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      @media(min-width: $xl) {
        flex-wrap: nowrap;
        align-items: center;
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 0 40px;
        overflow: hidden;
        margin-bottom: 32px;

        @media(min-width: $xl) {
          margin-bottom: 0;
        }

        &--user {
          @media(min-width: $sm) {
            width: 100%;
            justify-content: center;
          }

          @media(min-width: $lg) {
            width: initial;
            max-width: 320px;
          }

          .hero-swiper__bar__stats__item__values {
            text-align: left;
          }
        }

        &:not(:last-child) {
          @media(min-width: $lg) {
            border-right: solid 1px $grey-100;
          }
        }

        &__icon {
          width: 56px;
          height: 56px;
          margin-right: 15px;
          object-fit: contain;
          border-radius: 50%;
        }

        &__values {
          overflow: hidden;
          text-align: center;

          @media(min-width: $xl) {
            text-align: left;
          }
        }

        &__name {
          @include text(16px, $grey-500, bold);
        }

        &__value {
          @include heading-3($primary);
          @include crop-text;
        }
      }
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      margin-right: -12px;
      margin-bottom: -12px;

      & > * {
        flex-grow: 1;
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
