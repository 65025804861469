.homepage {
  &__section-claim {
    @include heading-2();
    max-width: 1080px;

    @media(min-width: $md) {
      @include heading-1();
    }
  }

  &__about-section {
    @media(max-width: $sm) {
      padding-bottom: 0 !important;
    }
  }
}
