.create-campaign-banner {
  padding: 52px 32px 36px;
  background-color: $white;
  
  @media(min-width: $md) {
    padding: 126px 86px;
  }

  @media(min-width: $xl) {
    padding: 126px 264px 126px 86px;
    background: $white url("/img/create-campaign-banner-bg.png") no-repeat right center;
    background-size: auto 100%;
  }

  &__text {
    margin-bottom: 34px;
    @include heading-3();

    @media(min-width: $sm) {
      @include heading-1();
    }

    &--highlight {
      color: $primary;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}
