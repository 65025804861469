.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  &__overlay {
    position: fixed;
    z-index: 51;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($black, .5);
    backdrop-filter: blur(3px);
  }

  &__wrapper {
    @include flex-center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 52;

    @media(min-width: $sm) {
      padding: 56px 24px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 969px;
    max-height: calc(100vh - 120px);
    overflow: hidden;
    box-shadow: 0 20px 40px rgba($black, 0.2);
    background-color: $white;
    position: relative;

    @media(min-width: $sm) {
      flex-direction: row;
      border-radius: 16px;
    }
  }

  &__aside {
    flex: 0 1 0;
    padding: 82px 50px;
    position: relative;
    z-index: 0;
    @include display-from($md, flex);

    @media(min-width: $lg) {
      flex: 0 1 340px;
    }

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 58px 24px 58px;
    overflow: auto;

    @media(min-width: $lg) {
      padding: 80px 94px 58px;
    }
  }

  &__close {
    @include flex-center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    z-index: 1;
    border-radius: 8px;
    background-color: $grey-100;
    border: none;
    cursor: pointer;
    margin-left: auto;

    @media(min-width: $md) {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    svg {
      display: block;
      pointer-events: none;
    }
  }

  &__title {
    @include heading-2($primary);
    margin-bottom: 4px;
  }

  &__desc {
    @include text(12px);
  }

  &__claim {
    @include text(16px);

    button {
      @include text(16px, $blue-500);
      background: none;
      cursor: pointer;
      border: none;
      outline: none;
      margin-left: 6px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__form {
    &__inputs {
      &__item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &__desc {
          margin-top: 10px;
          @include label($grey-500);
        }
      }
    }

    &__agreements {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      margin: 32px 0;

      .checkbox__label {
        a {
          color: $primary;
        }
      }
    }

    &__remember {
      @include text(16px, $blue-500);
    }

    &__submit {
      display: flex;
      flex-direction: column;

      &__desc {
        margin-top: 10px;
        text-align: center;
        @include label($grey-500);

        a {
          color: $primary;
        }
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-top: auto;
  }
}

.register-modal {
  &__desc {
    margin-bottom: 12px;
  }

  &__form {
    margin-top: 12px;
  }

  &__brand {
    margin-top: auto;
  }
}

.login-modal {
  &__form {
    margin: 36px 0;
  }

  &__submit {
    margin-top: 24px;
  }
}

.remember-password-modal {
  &__form {
    margin-top: 36px;
  }

  &__submit {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 36px;
  }
}
