.goals-timeline {
  &__track {
    position: relative;
    background-color: $grey-100;
    height: 6px;
    border-radius: 100px;

    &__progress {
      display: block;
      background: linear-gradient(90deg, lighten($primary, 45) 0%, $primary 100%);
      height: 100%;
      border-radius: 100px;
    }

    &__point {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $grey-100;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &--hidden {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        .goals-timeline__track__point__label--group {
          span {
            display: block;
          }

          &::after {
            display: none;
          }
        }
      }

      &::after {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 75%);
      }

      &__label {
        display: flex;
        flex-direction: column;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% + 10px);
        white-space: nowrap;
        font-size: 10px;
        color: $grey-500;
        font-weight: bold;

        @media(min-width: $sm) {
          font-size: 12px;
        }

        &--group {
          padding: 0 6px 6px;
          border-radius: 4px;
          background-color: $grey-50;

          span {
            &:not(:first-child) {
              margin-top: 4px;
              display: none;
            }
          }

          &::after {
            content: "...";
          }
        }
      }

      &--current {
        background-color: $white;
        border: solid 3px $primary;

        .goals-timeline__track__point__label {
          color: $primary;
          bottom: auto;
          top: -22px;
        }
      }

      &--passed {
        background-color: $white;
        border: solid 1px $primary;
      }
    }
  }
}
