.blog-grid{
  padding-top: 92px;
  &__items{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(Min(300px,100%), 1fr));
    grid-gap: 18px;

    @media(min-width: $md){
      grid-template-columns: repeat(auto-fill, minmax(Min(415px,100%), 1fr));
    }
    &__info{
      @include heading-2($grey-900);
    }
  }
  &__pagination{
    display: flex;
    justify-content: center;
    margin-bottom: 154px;
    margin-top: 56px;
  }
}
