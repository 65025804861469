.campaign-preview {
  padding-top: 40px + $navbar-height;
  padding-bottom: 110px;

  &__alerts {
    display: grid;
    grid-gap: 8px;
    margin-bottom: 16px;

    &__alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 58px;
      padding: 8px 16px;

      @media(min-width: $sm) {
        flex-direction: row;
      }

      &__icon {
        flex-shrink: 0;
        margin-bottom: 12px;

        @media(min-width: $sm) {
          margin-right: 12px;
          margin-bottom: 0;
        }
      }

      &__text {
        margin-bottom: 12px;
        text-align: center;
        @include text(14px, $grey-900, bold);

        @media(min-width: $sm) {
          margin-right: 24px;
          margin-bottom: 0;
          text-align: left;
        }

        @media(min-width: $md) {
          @include text(16px, $grey-900, bold);
        }
      }

      &__actions {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 12px;
        flex-shrink: 0;

        @media(min-width: $sm) {
          margin-left: auto;
        }
      }

      &--info {
        background-color: $blue-100;
        border: solid 1px $blue-500;

        .campaign-preview__alerts__alert__icon {
          path {
            fill: $blue-500;
          }
        }
      }

      &--warning {
        background-color: $yellow-100;
        border: solid 1px $yellow-500;

        .campaign-preview__alerts__alert__icon {
          path {
            fill: $yellow-500;
          }
        }
      }

      &--danger {
        background-color: $red-100;
        border: solid 1px $red-500;

        .campaign-preview__alerts__alert__icon {
          path {
            fill: $red-500;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;

    &--reverse {
      flex-direction: column;
    }

    @media(min-width: $lg) {
      flex-direction: row;
    }
  }

  &__main {
    width: 100%;
    margin-right: 18px;
  }

  &__aside {
    width: 100%;
    margin-bottom: 32px;

    @media(min-width: $lg) {
      max-width: 400px;
      margin-bottom: 0;
    }
  }
}
