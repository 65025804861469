.share-campaign-modal {
  .basic-modal {
    &__main {
      @media(min-width: $md) {
        max-width: 640px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: rgba($grey-500, .2);
      border-radius: 4px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &__header {
    min-height: 132px;
    position: relative;
    background-image: url("/img/modal-header-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px;
  }

  &__title {
    margin-bottom: 16px;
    text-align: center;
    @include heading-2($primary);
  }

  &__desc {
    margin-bottom: 38px;
    max-width: 320px;
    text-align: center;
    @include text(18px, $grey-500);
  }

  &__socials {
    margin-bottom: 32px;
  }
}
