@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin focus-outline {
  outline: none;
  box-shadow: 0 0 0 6px rgba($primary, 0.2);
  transition: box-shadow 350ms;
}

@mixin article-styles {
  h3 {
    margin-bottom: 16px;
    @include heading-2();
  }

  p {
    margin-bottom: 16px;
    @include text(16px, $grey-500);
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    margin-bottom: 16px;
    padding-left: 18px;

    li {
      @include text(16px, $grey-500);

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  figure {
    margin-bottom: 16px;
  }
}

@mixin display-from($size, $display:block) {
  display: none;

  @media(min-width: $size) {
    display: $display;
  }
}

@mixin crop-text() {
  overflow: hidden;
  text-overflow: ellipsis;
}
