.platform-details {
  &__header {
    display: flex;
    flex-direction: column;

    @media(min-width: $xl) {
      flex-direction: row;
      align-items: flex-end;
    }

    &__image {
      flex: 1 1 0;

      @media(min-width: $xl) {
        max-width: 854px;
      }

      img {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media(min-width: $xl) {
        flex: 1 1 0;
      }

      &__inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 24px 64px;
        background-color: $white;

        @media(min-width: $md) {
          padding: 44px 52px 100px
        }
      }
    }

    &__title {
      padding: 42px 24px 0px;
      background-color: $white;
      @include heading-1plus();

      @media(min-width: $md) {
        padding: 82px 52px 44px;
      }

      @media(min-width: $xl) {
        background-color: $transparent;
      }
    }

    &__desc {
      margin-bottom: 48px;
      max-width: 460px;
      @include text(20px, $grey-500, bold);
    }

    &__more {
      display: flex;
      align-items: center;
      @include text(20px, $grey-900, bold);

      svg {
        margin-left: 8px;

        path {
          fill: $primary;
        }
      }
    }
  }

  &__content {
    padding: 64px 24px;
    background-color: $white;

    @media(min-width: $lg) {
      padding: 102px 110px 130px;
    }
  }
}
