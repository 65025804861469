.about-platform {
  &__upper {
    position: relative;
    height: 420px;
    overflow: hidden;

    @media(min-width: $md) {
      height: 670px;
    }

    .simple-parallax-initialized {
      display: flex;
      justify-content: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      padding: 64px 24px;

      @media(min-width: $md) {
        padding: 78px 110px;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        background: linear-gradient($transparent, rgba($black, 0.6));
      }
    }

    &__title {
      @include heading-1($white);
      margin-top: auto;
      margin-bottom: 32px;
      max-width: 480px;
    }
  }

  &__lower {
    display: flex;
    flex-direction: column;
    padding: 98px 24px 0 24px;
    background-color: $white;

    @media(min-width: $md) {
      padding: 98px 64px 0 110px;
    }

    @media(min-width: $xl) {
      flex-direction: row;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 524px;
      margin-bottom: 82px;
    }

    &__title {
      @include heading-1();
      margin-bottom: 20px;
    }

    &__text {
      @include text(18px, $grey-500, bold);
      margin-bottom: 56px;
    }

    &__more {
      display: flex;
      align-items: center;
      @include text(20px, $grey-900, bold);

      svg {
        margin-left: 8px;
      }
    }

    &__image {
      img {
        max-width: 100%;
        display: block;
      }
    }
  }
}
