.newsletter-form {
  display: flex;
  flex-direction: column;

  &__title {
    @include heading-3($white);
    margin-bottom: 10px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid $grey-100;
    border-radius: 8px;
    background-color: $white;

    @media(min-width: $md) {
      flex-direction: row;
    }

    &__inner {
      @include text(16px);
      flex: 1 0 0;
      padding-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
      border: none;
      background: none;
      border-radius: 8px;

      @media(min-width: $md) {
        margin-bottom: 0;
      }

      &:focus {
        @include focus-outline;
      }

      &::placeholder {
        color: $grey-500;
      }
    }

    &__submit {
      width: 100%;

      @media(min-width: $md) {
        width: auto;
      }
    }
  }

  &__agreement {
    @include label($white);
  }
}
