
.blog-card{
  display: flex;
  flex-direction: column;
  background-color: $white;
  &__thumbnail{
    position: relative;
    width: 100%;
    padding-top: 75%;
    &__img{
      border-radius: 4px 4px 0 0;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius:0 0 4px 4px;
    padding:20px 16px 30px;
    &__title{
      font-size: 18px;
      font-weight: bold;
      &__more{
        text-decoration: none;
      }
      &__more:hover{
        text-decoration: none;
      }
    }
    &__desc{
      margin-top: 14px;
      color: $grey-500;
      line-height: 24px;
      word-break: break-word;
    }
    &__more{
      margin-right: auto;
      margin-top: auto;
    }
  }
}
